import { Controller } from "react-hook-form";

export const FormImgUpload = (props) => {

    const { name, control, label, rules, image, removeMedia, host } = props;

    const img = image && typeof image !== "string" ? Array.from(image)[0] : image;

    const baseUrl = host;

    return (
    <>
        {img &&
            <div className="upload-img">
                <img
                    src={
                        img.type && img.type.includes("image") ?
                            img.size ?
                                URL.createObjectURL(img)
                            :
                                `${baseUrl}${img.url}`
                        : `${baseUrl}${img}`
                    }
                    alt=""
                />
                {( !rules || (rules && !rules.required) ) &&
                    <span className="remove-media" onClick={removeMedia}>×</span>
                }
            </div>
        }

        <label>{label}</label>
                                    
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={[]}
            render={({ field: { onChange, ref }, fieldState: { error } }) => <>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        onChange(e.target.files);
                    }}
                    ref={ref}
                />
                {error && <span className="error">Ce champ est requis</span>}
            </>}
        />
    </>);
};