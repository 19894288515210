import './info.scss';
import { Link } from 'react-router-dom';

export const Info  = (props) => {
    const { title, content, btnTitle, btnLink, onClick } = props;
    
	return (
        <div className="info">
            <h2>{title}</h2>
            <p>{content}</p>

            {onClick ? (
                <div onClick={onClick}>
                    {btnTitle}
                </div>
            ) : (
                <Link to={btnLink}>
                    <div>{btnTitle}</div>
                </Link>
            )}
            
        </div>
	);
};
