import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

export const ExportExcel = ({ data, fileName, groups, selectedRow }) => {
    const exportToExcel = () => {
        // Création d'un nouveau classeur
        const workbook = XLSX.utils.book_new();

        // Conversion des données JSON en une feuille de calcul
        let worksheet = XLSX.utils.json_to_sheet(data);

        // Ajout de la feuille de calcul au classeur
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Génération du fichier Excel
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Enregistrement du fichier
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        if (groups !== undefined) {
            saveAs(blob, `${groups}.xlsx`);
        } else {
            saveAs(blob, `${fileName}.xlsx`);
        }
    };

    return (
        <Button variant="contained" color="primary" onClick={exportToExcel}>
            <FontAwesomeIcon icon={faFileExport} />
            &nbsp;
            Exporter
        </Button>
    );
};

