import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { FormInputText } from '../UI/Form/FormInputText';
import { FormButtonSubmit } from '../UI/Form/FormButtonSubmit';
import { FormAutocomplete } from "../UI/Form/FormAutocomplete";
import { invitMemberInWorkspace } from '../../store/index.service';
import { Toastify } from "../UI/Toastify/Toastify";


export const InvitMemberInWorkspace = ({ handleClosePopup }) => {
    const { control, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => {
        setLoading(true);
        invitMemberInWorkspace(data).then(res => {
            setLoading(false);
            handleClosePopup();
            Toastify("updateContact", "success");
        });
    }

    return (
        <div className='popupCreateHorseQuick popup-container'>

            <h2>Inviter un membre</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <FormInputText name="email" control={control} label="Email" rules={{ required: true }} />
                </div>
                <div className="form-group">
                    <FormAutocomplete
                        name="role"
                        control={control}
                        label="Role"
                        options={[
                            {value: 'admin', label: 'Administrateur'},
                            {value: 'support', label: 'Support'},
                            {value: 'comptable',label: 'Comptable'},
                            {value: 'editeur', label: 'Editeur'}
                        ]}
                        getOptionLabel={option => option.label}
                        renderOption={(props, option) =>
                            <li {...props} key={option.value}>
                                {option.label}
                            </li>
                        }
                    />
                </div>


                <div className="btn-container">
                    <Button variant="text" size="small" className="cancel" onClick={handleClosePopup}>Annuler</Button>
                    <FormButtonSubmit loading={loading} label="Inviter" />
                </div>
            </form>

        </div>
    )
}