import './editeur.scss';
import React, { useState, useRef, useContext, useEffect } from 'react';
import { Controller } from "react-hook-form";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { StoreContext } from '../../../store/store';
import { PopupChatGpt } from '../../UI/Popup/PopupChatGpt';
import { UpgradePlan } from '../../UI/Popup/UpgradePlan';
import { Popup } from "../../UI/Popup/Popup";
import { getFieldCustom } from '../../../store/index.service';

export const Editeur = (props) => {
    const { control, ia, nbCaract = 1000, name = "content", watch, idToolbar = "" } = props;
    const { workspace } = useContext(StoreContext);
    const [text, setText] = useState(watch(name) || "");
    const quillRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [showPopupUpgradePlan, setShowPopupUpgradePlan] = useState(false);
    const [showPopupChatGpt, setShowPopupChatGpt] = useState(false);
    const [isVariablesMenuVisible, setIsVariablesMenuVisible] = useState(false);
    const [listFieldCustom, setListFieldCustom] = useState([]);

    const toggleEmojiPicker = () => {
        setIsPickerVisible(!isPickerVisible);
    };

    const addEmoji = (emoji) => {
        const editor = quillRef.current.getEditor();
        const cursorPosition = editor.getSelection()?.index || 0;
        editor.insertText(cursorPosition, emoji.native);
        editor.setSelection(cursorPosition + emoji.native.length);
        setIsPickerVisible(false);
        editor.focus();
    };

    const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
        const sanitizedValue = pastedData.replace(/<\/?[^>]+(>|$)/g, "");
        const editor = quillRef.current.getEditor();
        const selection = editor.getSelection();
    
        if (selection) {
            editor.clipboard.dangerouslyPasteHTML(selection.index, sanitizedValue);
            setText(sanitizedValue);
        } else {
            console.error("No selection in the editor.");
        }
    };
    
    useEffect(() => {
        const editor = quillRef.current.getEditor();
        editor.root.addEventListener('paste', handlePaste);

        getFieldCustom().then((result) => {
            setListFieldCustom(result.data.fieldCustom);
        });

        return () => {
            editor.root.removeEventListener('paste', handlePaste);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsVariablesMenuVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleUseGeneratedContent = (generatedContent) => {
        setText(generatedContent);
        quillRef.current.getEditor().clipboard.dangerouslyPasteHTML(generatedContent);
    };

    const modules = {
        toolbar: {
            container: `#toolbar${idToolbar}`
        }
    };

    const VariablesMenu = ({ onSelectVariable }) => (
		<div ref={dropdownRef} style={{ display: isVariablesMenuVisible ? 'block' : 'none' }} className="dropdown-var">
			{listFieldCustom.map(variable => (
				<div key={variable} onClick={() => onSelectVariable(variable)}>
					{variable}
				</div>
			))}
		</div>
	);

    const onSelectVariable = (variable) => {
        const editor = quillRef.current.getEditor();
    
        // Récupérer la longueur totale du texte dans l'éditeur, puis retirer 1 pour éviter de compter le saut de ligne
        const endPosition = editor.getLength() - 1; // La position finale sans saut de ligne
    
        // Insérer la variable dynamique à la fin du texte
        editor.insertText(endPosition, `{{${variable}}}`);
    
        // Mettre à jour la position du curseur après l'insertion, à la fin du texte
        editor.setSelection(endPosition + variable.length + 4);
    
        // Assurer que l'éditeur reste focalisé après l'insertion
        editor.focus();
    
        // Masquer le menu des variables après insertion
        setIsVariablesMenuVisible(false);
    };
    
    return (
        <div className="editeur">
            <div className="form-group">
                <div id={`toolbar${idToolbar}`} className="toolbar-custom">
                    <button className="ql-bold"></button>
                    <button className="ql-italic"></button>
                    <button className="ql-underline"></button>
                    <button id="emoji-button" type="button" onClick={toggleEmojiPicker}>😀</button>
                    <button type="button" className="btnAction btnVar" onClick={() => setIsVariablesMenuVisible(!isVariablesMenuVisible)}>Insérer une Variable</button>
                    <VariablesMenu onSelectVariable={onSelectVariable} />
                    <div className="toolbar-right">
                        {ia && (
                            workspace?.feature === null ? (
                                <button
                                    type="button"
                                    className="ql-gpt btnVar disable-btn"
                                    onClick={() => setShowPopupUpgradePlan(true)}
                                >
                                    Demander à l'IA
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="ql-gpt btnVar"
                                    onClick={() => setShowPopupChatGpt(true)}
                                >
                                    Demander à l'IA
                                    {workspace?.feature?.limitedIa && (
                                        <>
                                            &nbsp;- {workspace.workspaceCountIaNeeded} /10
                                        </>
                                    )}
                                </button>
                            )
                        )}

                        <div className="char-count">
                            {text.replace(/<\/?[^>]+(>|$)/g, "").length > nbCaract ? (
                                <><span className="error">{text.replace(/<\/?[^>]+(>|$)/g, "").length}</span> / {nbCaract}</>
                            ) : (
                                <>{text.replace(/<\/?[^>]+(>|$)/g, "").length} / {nbCaract}</>
                            )}
                        </div>
                    </div>
                </div>

                <Controller
                    name={name}
                    control={control}
                    rules={{ required: "Ce champ est obligatoire" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className={error ? "error" : undefined}>
                            <ReactQuill
                                ref={quillRef}
                                value={text}
                                onChange={(val) => {
                                    setText(val);
                                    onChange(val);
                                }}
                                modules={modules}
                                placeholder="Tapez votre texte ici..."
                            />
                        </div>
                    )}
                />

                {isPickerVisible && (
                    <div className="emojiPicker">
                        <Picker data={data} onEmojiSelect={addEmoji} />
                    </div>
                )}
            </div>

            {showPopupChatGpt && (
                <Popup closePopup={() => setShowPopupChatGpt(false)} customContent={true}>
                    <PopupChatGpt chatGptText={handleUseGeneratedContent} />
                </Popup>
            )}

            {showPopupUpgradePlan && (
                <Popup closePopup={() => setShowPopupUpgradePlan(false)} customContent={true}>
                    <UpgradePlan />
                </Popup>
            )}
        </div>
    );
};
