import "./messaging.scss"
import { Sidebar } from "../../components/Messaging/Sidebar/Sidebar";
import { Conversation } from "../../components/Messaging/Conversation/Conversation";
import { MessagingProvider } from "../../store/messagingContext";

export const Messaging = () => {
    
    return (
        <div className="pageContent">
            <div className="messaging">
                <MessagingProvider>
                    <Sidebar />
                    <Conversation />
                </MessagingProvider>
            </div>
        </div>
    )
}