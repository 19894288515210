import './importContact.scss';
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { SelectFile } from './SelectFile/SelectFile';
import { MappingFile } from './MappingFile/MappingFile';
import { ConfirmImport } from './ConfirmImport/ConfirmImport';
import { importContact } from '../../../store/services/contact';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const ImportContact = ({ open, handleClose, setRefreshDataGroup, handleGetData }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [fileData, setFileData] = useState(null);
    const steps = ['Sélectionner le fichier', 'Mapper les champs', 'Import terminé'];
    const [nbContact, setNbContact] = useState(0);

    const handleFileProcessed = (data) => {
        setFileData(data);
        setCurrentStep(2);
    };

    const handleMappingConfirmed = (mappings, idContactImport, selectedGroups) => {
        importContact(mappings, idContactImport, selectedGroups).then((res) => {
            setNbContact(res.data);
            setCurrentStep(3);
            setRefreshDataGroup(true);
            handleGetData();
        });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="close-modal" onClick={handleClose} />
            <div className="stepper">
                <Stepper activeStep={currentStep - 1} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <DialogContent>
                {currentStep === 1 && <SelectFile onFileProcessed={handleFileProcessed} />}
                {currentStep === 2 && <MappingFile data={fileData} onConfirm={handleMappingConfirmed} />}
                {currentStep === 3 && <ConfirmImport nbContact={nbContact} />}
            </DialogContent>
            <DialogActions>
                {currentStep === 3 && <Button onClick={handleClose}>Terminé</Button>}
            </DialogActions>
        </Dialog>
    );
};

