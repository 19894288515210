import api from '../api';

export const getListProduct = async () => {
    const result = await api.get('/api/stripe/product' , {});
    return(result.data);
}

export const getListInvoice = async () => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/stripe/invoice' , {});
    return(result.data);
}

export const createCheckoutSession = (data) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/stripe/create-checkout-session', data);

export const removeSubscription = (data) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/stripe/cancel-subscription', data);