import { useEffect } from 'react';

export const FacebookSDK = (props) => {
    
    useEffect(() => {
        const loadFacebookSDK = () => {
            if (window.FB)
                return;

            window.fbAsyncInit = function() {
                window.FB.init({
                    appId            : '688970422817279',
                    xfbml            : false,
                    version          : 'v18.0',
                    cookie           : true
                });
            };

            const script = document.createElement('script');
            script.async = true;
            script.defer = true;
            script.crossOrigin = 'anonymous';
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            document.body.appendChild(script);
        };

        loadFacebookSDK();
    }, []);

    return null;
};

