import React, { useState, useEffect } from 'react';
import { getListGroups, getFieldCustom } from '../../../../store/index.service';
import { FormAutocomplete } from '../../../UI/Form/FormAutocomplete';
import { useForm } from 'react-hook-form';

export const MappingFile = ({ data, onConfirm }) => {
    const [mappings, setMappings] = useState({});
    const [fields, setFields] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [newCustomField, setNewCustomField] = useState("");
    const [idContactImport, setIdContactImport] = useState(null);
    const [listGroup, setListGroup] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [listFieldCustom, setListFieldCustom] = useState([]);
    const [errorPhone, setErrorPhone] = useState(false);
    const { control } = useForm();

    useEffect(() => {
        try {
            const parsedData = data;
            if (parsedData.headers) {
                const headers = parsedData.headers;
                const fieldKeys = Object.keys(headers);
                const fieldValues = Object.values(headers);
                const mappedFields = fieldKeys.map((key, index) => ({
                    key,
                    value: fieldValues[index],
                }));
                setFields(mappedFields);
            }

            if (parsedData.idContactImport) {
                setIdContactImport(parsedData.idContactImport);
            }
        } catch (error) {
            console.error("Erreur d'analyse JSON : ", error);
        }

        getListGroups()
            .then((res) => {
                setListGroup(res);
            });

        getFieldCustom().then((result) => {
            setListFieldCustom(result.data.fieldCustom);
        });

    }, [data]);

    const handleMappingChange = (header, fieldValue) => {
        setMappings({ ...mappings, [header]: fieldValue });
    };

    const handleAddCustomField = (newField) => {

        // Vérifie que le champ n'existe pas déjà
        if (customFields.includes(newField)) {
            return;
        }

        // Vérifie que le champs n'est pas vide
        if (newField.trim() === "") {
            return;
        }

        // Ajoute le champ à la liste des champs personnalisés
        setCustomFields([...customFields, newField]);
        setNewCustomField(""); // Efface le champ de saisie
    };

    const handleGroupChange = (e) => {
        setSelectedGroups(e);
    };

    const checkIfPhoneIsMapped = () => {
        if (Object.values(mappings).includes("Téléphone")) {
            onConfirm(mappings, idContactImport, selectedGroups);
        } else {
            setErrorPhone(true);
        }
    }

    return (
        <div className="mappingFile">
            <p>Associez les champs de votre fichier avec les champs de votre modèle de contact</p>
            {errorPhone && <p className="error">Vous devez associer le champ Téléphone pour continuer</p>}

            <div className="addCustomField">
                <input type="text" value={newCustomField} onChange={(e) => setNewCustomField(e.target.value)} />
                <button onClick={() => handleAddCustomField(newCustomField)}>Ajouter un champ personnalisé</button>
            </div>

            {fields.map((header, index) => (
                <div key={index}>
                    <label>{header.value}: </label>
                    <select onChange={(e) => handleMappingChange(header.value, e.target.value)}>
                        <option value="">Sélectionner un champ</option>
                        {listFieldCustom.map(field => (
                            <option key={field} value={field}>{field}</option>
                        ))}
                        {customFields.map((customField, index) => (
                            <option key={`custom-${index}`} value={customField}>{customField}</option>
                        ))}
                    </select>
                </div>
            ))}

            <div className="separator"></div>

            <p>Souhaitez vous ajoutez vos contact à un ou plusieurs groupe</p>

            <div className="mappingGroup">
                <FormAutocomplete
                    label="Groupes"
                    name="groups"
                    control={control}
                    options={listGroup}
                    getOptionLabel={option => option.title}
                    onChangeCustom={handleGroupChange}
                    renderOption={(props, option) =>
                        <li {...props} key={option.id}>
                            {option.title}
                        </li>
                    }
                    multiple
                />
            </div>

            <button className="addBtn" onClick={() => checkIfPhoneIsMapped()}>Suivant</button>
        </div>
    );
};
