import './carousel.scss';
import defaultCaroussel from "../../../assets/images/default-caroussel.png";
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Sortable from 'sortablejs';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faGripVertical, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';

// Components
import { Editeur } from "../Editeur/Editeur";
import { ListButtonAction } from "../Button/ListButtonAction";
import { useFieldArray } from 'react-hook-form';
import { UploadFile } from './UploadFile';

export const Carousel = (props) => {
    const { control, watch, setValue } = props;
    const sortableRef = useRef(null);
    const { fields: carouselItems, append, remove, move, update } = useFieldArray({ name: 'carouselItems', control });

    // Initial state for collapse
    const [openIndexes, setOpenIndexes] = useState([]);

    useEffect(() => {
        if (sortableRef.current) {
            Sortable.create(sortableRef.current, {
                animation: 150,
                handle: '.handle',
                onEnd: (evt) => {
                    const { oldIndex, newIndex } = evt;
                    move(oldIndex, newIndex);
                }
            });
        }
    }, []);

    const toggleCollapse = (index) => {
        setOpenIndexes((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };

    const addItem = useCallback((event) => {
        event.preventDefault();
        if(carouselItems.length >= 10) return;

        let listButtonsToAppend = [];
        if (carouselItems.length > 0 && carouselItems[0].listButtons?.length > 0) {
            listButtonsToAppend = carouselItems[0].listButtons;
        }
        append({ 
            content: '',
            media: '',
            listButtons: listButtonsToAppend,
        });
        toggleCollapse(carouselItems.length);
    }, [carouselItems, append]);

    const removeItem = useCallback((index) => {
        remove(index);
    }, [remove]);

    const handleFile = (fileUploaded, index) => {
        setValue(`carouselItems[${index}].media`, fileUploaded);
    };

    const handleAddBtnToAllSlides = useCallback((data) => {
        carouselItems?.forEach((slide, index) => {
            update(index, {
                ...(watch(`carouselItems[${index}]`) || {}),
                listButtons: [
                    ...(slide.listButtons || []),
                    data,
                ],
            });
            if (!openIndexes.includes(index)) toggleCollapse(index);
        });
    }, [carouselItems, openIndexes, toggleCollapse, update, watch]);

    const handleRemoveBtnToAllSlides = useCallback((indexBtn) => {
        carouselItems?.forEach((slide, index) => {
            const newSlideListButtons = [...(slide.listButtons || [])];
            newSlideListButtons.splice(indexBtn, 1);
            update(index, {
                ...slide,
                listButtons: newSlideListButtons,
            });
        });
    }, [carouselItems, update]);

    return (
        <div className="form-group">
            <div className="carousel">
                <div className="carousel-info">
                    <h3>Fiches carrousel  
                        <Tooltip title="Le carrousel est limité à 10 cartes">
                            <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                        </Tooltip>
                    </h3>
                    <p>{carouselItems.length} sur 10 cartes ajoutées</p>
                </div>

                <div className="carousel-container" ref={sortableRef}>
                    {carouselItems.map((item, index) => (
                        <div key={item.id} className="carousel-item">
                            <div className="item">
                                <FontAwesomeIcon icon={faGripVertical} className="handle" />
                                <div className="media">
                                <img
                                    src={
                                        watch(`carouselItems[${index}].media`) 
                                        ? URL.createObjectURL(watch(`carouselItems[${index}].media`)) 
                                        : defaultCaroussel
                                    }
                                    alt="placeholder"
                                />
                                </div>
                                <div className="carousel-item-content">
                                    <div className="message" dangerouslySetInnerHTML={{ __html: watch(`carouselItems[${index}].content`) || 'Contenu de la carte' }}></div>
                                </div>
                                <div className="actions">
                                    <FontAwesomeIcon 
                                        icon={faEdit} 
                                        onClick={() => toggleCollapse(index)} 
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faRemove}
                                        onClick={() => removeItem(index)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>

                            {openIndexes.includes(index) && (
                                <div className="collapse-content">
                                    <div className="collapse-media">
                                        <UploadFile
                                            handleFile={(file) => handleFile(file, index)}
                                            watchedPreview={watch(`carouselItems[${index}].media`) 
                                                ? URL.createObjectURL(watch(`carouselItems[${index}].media`)) 
                                                : null
                                            }
                                        />
                                        <Editeur
                                            control={control}
                                            watch={watch}
                                            nbCaract={65}
                                            ia={false}
                                            name={`carouselItems[${index}].content`}
                                            idToolbar={`sliderContent${index}`}
                                        />
                                    </div>
                                    <div className="list-buttons">
                                        <ListButtonAction
                                            control={control}
                                            setValue={setValue}
                                            name={`carouselItems[${index}].listButtons`}
                                            nomarket
                                            listOnly
                                            deleteTooltip="Supprimer ce bouton le supprimera sur toutes les slides"
                                            handleRemove={handleRemoveBtnToAllSlides}
                                        />
                                    </div>
                                </div>
                            )}

                        </div>
                    ))}
                </div>

                <div className="btn-add">
                    <button onClick={addItem} disabled={carouselItems.length >= 10}>
                        <FontAwesomeIcon icon={faPlus} /> Ajouter une carte
                    </button>
                </div>
                <ListButtonAction
                    control={control}
                    setValue={setValue}
                    nomarket
                    btnAddOnly
                    btnLabel="+ Ajouter un bouton aux slides"
                    handleAppend={handleAddBtnToAllSlides}
                />
            </div>
        </div>
    );
};
