import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { useFieldArray } from 'react-hook-form';

// Components
import { ButtonCallActionWebsite } from './ButtonCallActionWebsite';
import { ButtonCallActionPhone } from './ButtonCallActionPhone';
import { ButtonCallActionOffer } from './ButtonCallActionOffer';
import { ButtonCallActionStopMarket } from './ButtonCallActionStopMarket';

// Constants
import { BUTTONS_TYPE } from '../../../constants';

import './buttonAction.scss';

export const ListButtonAction = (props) => {
    const {
        control,
        setValue,
        name = 'listButtons',
        nomarket = false,
        btnAddOnly,
        listOnly,
        btnLabel,
        handleAppend,
        handleRemove,
        deleteTooltip,
    } = props;
    const [isListBtnOpen, setIsListBtnOpen] = useState(false);
    const containerBtnRef = useRef(null);
    const { fields: listButtons, append, remove } = useFieldArray({ name, control });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerBtnRef.current && !containerBtnRef.current.contains(event.target)) {
                setIsListBtnOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const listButtonConsultWeb = useMemo(() => (
        listButtons.filter((button) => button.type === BUTTONS_TYPE.web)
    ), [listButtons]);

    const listButtonPhone = useMemo(() => (
        listButtons.filter((button) => button.type === BUTTONS_TYPE.phone)
    ), [listButtons]);

    const listButtonStopMarket = useMemo(() => (
        listButtons.filter((button) => button.type === BUTTONS_TYPE.stopMarket)
    ), [listButtons]);

    const handleAddButton = useCallback((type) => {
        switch (type) {
            case BUTTONS_TYPE.web:
                if (listButtonConsultWeb.length < 2) {
                    (handleAppend || append)({ text: '', url: '', type });
                }
                break;
            case BUTTONS_TYPE.phone:
                if (listButtonPhone.length < 1) {
                    (handleAppend || append)({ text: '', phoneNumber: '', type });
                }
                break;
            case BUTTONS_TYPE.stopMarket:
                if (listButtonStopMarket.length < 1) {
                    (handleAppend || append)({ text: 'Arrêter les promotions', type });
                    setValue('footer', 'Pas intéressé(e) ? Appuyez sur Arrêter les promotions');
                }
                break;
            default:
                break;
        }
        setIsListBtnOpen(false);
    }, [listButtonConsultWeb, listButtonPhone, listButtonStopMarket, setIsListBtnOpen, handleAppend]);

    const displayedListButtons = useMemo(() => (
        listButtons.map((button, index) => {
            const buttonProps = {
                control,
                index,
                remove: handleRemove || remove,
                name,
                deleteTooltip,
            }
            if (button.type === BUTTONS_TYPE.web) return <ButtonCallActionWebsite key={index} {...buttonProps} />;
            if (button.type === BUTTONS_TYPE.phone) return <ButtonCallActionPhone key={index} {...buttonProps} />;
            if (button.type === BUTTONS_TYPE.stopMarket) return <ButtonCallActionStopMarket key={index} {...buttonProps} />;
            return;
        })
    ), [listButtons, handleRemove, remove, name, deleteTooltip, control]);

    const shouldDisplayTitle = useMemo(() => {
        if (btnAddOnly) return false;
        if (listOnly && listButtons.length < 1) return false;
        return true;
    }, [btnAddOnly, listOnly, listButtons]);

    return (
        <div className="listButtonAction">
            {shouldDisplayTitle && (
                <div className="info-form">
                    <p className="title">
                        Boutons <span>Facultatif</span>
                        <Tooltip title="Créez des boutons qui permettent aux clients de répondre à votre message ou d'effectuer une action.">
                            <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                        </Tooltip>
                    </p>
                </div>
            )}
            {!listOnly && (
                <div className="form-group">
                    <div className="containerBtn" ref={containerBtnRef}>
                        {isListBtnOpen && (
                            <div className="listBtn">
                                <div
                                    className={`action ${listButtonConsultWeb.length >= 2 ? 'disabled' : ''}`}
                                    onClick={() => handleAddButton(BUTTONS_TYPE.web)}
                                >
                                    <p>Consulter le site web</p>
                                    <span>2 boutons maximum</span>
                                </div>
                                <div
                                    className={`action ${listButtonPhone.length >= 1 ? 'disabled' : ''}`}
                                    onClick={() => handleAddButton(BUTTONS_TYPE.phone)}
                                >
                                    <p>Appeler le numéro</p>
                                    <span>1 bouton maximum</span>
                                </div>
                                {!nomarket && (
                                    <div
                                        className={`action ${listButtonStopMarket.length >= 1 ? 'disabled' : ''}`}
                                        onClick={() => handleAddButton(BUTTONS_TYPE.stopMarket)}
                                    >
                                        <p>Désactivation marketing</p>
                                        <span>Recommandé</span>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="btnAction" onClick={() => setIsListBtnOpen(!isListBtnOpen)}>
                            {btnLabel || "+ Ajouter un bouton"}
                        </div>
                    </div>
                </div>
            )}
            {!btnAddOnly && (
                displayedListButtons
            )}
        </div>
    );
};
