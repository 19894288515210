import './requestPassword.scss';
import media from '../../../assets/images/WhatsApp-marketing-campaign-1.jpeg';
import logoGreen from '../../../assets/images/logo-green.svg';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { Button } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import { requestPassword } from "../../../store/index.service";

export const RequestPassword = () => {
    const { handleSubmit, control } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [valid, setValid] = useState();

    const onSubmit = (data) => {
        setLoading(true);

        requestPassword(data)
            .then(res => {
                setError(false);
                setValid(true);
            })
            .catch(err => {
                setError(
                    err.response.status === 401 ? err.response.status : "default"
                );
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className="request-password">

            <div className="login-media">
                <img src={media} alt="media" />
            </div>

            <div className="login-form">
                <div className="logo-whakup">
                    <img src={logoGreen} alt="logo" />
                </div>

                <div className="title">
                    <h1>Mot de passe oublié</h1>
                </div>

                <div className="form">
                    <form name="login" className="register-form" onSubmit={handleSubmit(onSubmit)}>

                        { error &&
                            <div className='error-msg'>
                                <span>Une erreur est survenu</span>
                            </div>
                        }

                        { valid &&
                            <div className='success-msg'>
                                <span>Veuillez consultez votre adresse e-mail pour réinitialiser votre mot de passe</span>
                            </div>
                        }

                        <div className="form-group">
                            <FormInputText name="email" control={control} rules={{ required: true }} label="Email" type="email" />
                        </div>

                        <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                            <ClipLoader color={"#fff"} loading={loading} size={20} cssOverride={{ marginRight: 5 }} />
                            Envoyer
                        </Button>

                        <div className="form-group">
                            <p className="no-account">Déjà un compte ? <Link to="/">Se connecter</Link></p>
                        </div>
                    </form>
                </div>

            </div>
        </div>
  	);
} 