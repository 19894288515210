import './uploadFile.scss';
import defaultCaroussel from "../../../assets/images/default-caroussel.png";
import React, { useRef, useState, useMemo } from 'react';

export const UploadFile = ({ handleFile, watchedPreview }) => {
    const hiddenFileInput = useRef(null);
    const [preview, setPreview] = useState(null); // Pour afficher l'aperçu de l'image

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0]; // Sélection du fichier
        if (fileUploaded) {
            setPreview(URL.createObjectURL(fileUploaded)); // Aperçu de l'image
            handleFile(fileUploaded); // Transmettre le fichier réel (File object)
        }
    };

    const imgPreview = useMemo(() => preview || watchedPreview, [preview, watchedPreview]);

    return (
        <div className="upload-file">
            <button className="button-upload" onClick={handleClick} type="button">
                <div className="image-container">
                    {imgPreview ? (
                        <img src={imgPreview} alt="Aperçu du fichier" className="image-preview" />
                    ) : (
                        <img
                            src={defaultCaroussel}
                            alt="Upload"
                            className="default-image"
                        />
                    )}
                </div>
            </button>
            <input
                type="file"
                accept="image/*"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
            />
        </div>
    );
};
