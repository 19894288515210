import { Button } from '@mui/material';

export const ConfirmDelete = ({ handleClosePopup, handleDelete, msg = "" }) => {

    const handleSubmit = () => {
        handleDelete();
        handleClosePopup();
    }

    return (
        <div className='popupCreateHorseQuick'>

            <p>
                <br/>
                Êtes-vous sûr de vouloir supprimer {msg} ?
            </p>

            <div className="btn-container">

                <Button 
                    variant="text"
                    size="small"
                    className="cancel"
                    onClick={handleClosePopup}
                >
                    Annuler
                </Button>

                <Button 
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}
                >
                    Valider
                </Button>

            </div>

        </div>
    )
}