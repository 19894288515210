import './template.scss';
import iconDanger from '../../assets/images/icons/icon-danger.svg';
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { FormButtonSubmit } from "../../components/UI/Form/FormButtonSubmit";
import { Base } from "../../components/Template/Base/Base";
import { Title } from "../../components/Template/Title/Title";
import { Carousel } from "../../components/Template/Carousel/Carousel";
import { Editeur } from "../../components/Template/Editeur/Editeur";
import { Footer } from "../../components/Template/Footer/Footer";
import { ListButtonAction } from "../../components/Template/Button/ListButtonAction";
import { WhatsAppExample } from "../../components/WhatsAppExample/WhatsAppExample";
import { Toastify } from "../../components/UI/Toastify/Toastify";
import { convertToBase64 } from '../../helpers';
import { createTemplate } from '../../store/index.service';

export const CreateTemplate = () => {
    const { handleSubmit, control, watch, setValue, getValues } = useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const watchForm = watch();

    const onSubmit = async (data) => {
        setLoading(true);
        data['language'] = "en_US";

        if (data.content.replace(/<\/?[^>]+(>|$)/g, "").length > 1020) {
            Toastify("toMuchText", "error");
            setLoading(false);
            return;
        }

        if (data['content'].length === 0) {
            Toastify("emptyText", "error");
            setLoading(false);
            return;
        }
    
        // Attendre la conversion en base64 pour l'image si nécessaire
        if (data['selectTitle']) {
            if(data['selectTitle'].id === 3 && (data.image || data.video)) {
                try {

                    if (data.video) {
                        console.log(data.video);
                        const videoBase64 = await convertToBase64(data.video[0]);
                        console.log(videoBase64);
                        data['video'] = videoBase64;
                    }
                    
                    if (data.image) {
                        const imageBase64 = await convertToBase64(data.image[0]);
                        data['image'] = imageBase64;
                    }
                } catch (error) {
                    setLoading(false);
                    Toastify("error", "error", { detail: "Problème lors de la conversion de l'image" });
                    return;
                }
            }
        }

        // Convertir les images du carrousel en Base64
        if (data['carouselItems']) {
            for (let i = 0; i < data['carouselItems'].length; i++) {
                const media = data['carouselItems'][i].media;

                // Si `media` est un objet `File`, on le convertit en Base64
                if (media instanceof File || media instanceof Blob) {
                    try {
                        const imageBase64 = await convertToBase64(media);
                        data['carouselItems'][i].media = imageBase64;
                    } catch (error) {
                        setLoading(false);
                        Toastify("error", "error");
                        return;
                    }
                }
            }
        }

        try {
            await createTemplate(data);
            Toastify("createTemplate", "success", { name: data.name });
            navigate('/template');
        } catch (err) {
            //console.error("Erreur lors de la création du template", err);
            if (err.response && err.response.status === 410)
                Toastify("errorTemplateName", "error");
            if (err.response && err.response.status === 409)
                Toastify("error", "error");
        } finally {
            setLoading(false);
        }
        
    }

    return (
        <div className="template">
            <div className="container">

                <div className="content">

                    <div className="info-meta">
                        <Link to="/faq">
                            <img src={iconDanger} alt="danger" />
                            Note : Pour éviter d'être BAN de Metas aller voir la <span>FAQ</span>
                        </Link>
                    </div>

                    <div className="title-page">
                        <h1>Créer un template</h1>
                        <p>Créez un template en remplissant les informations suivantes</p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Base control={control} />

                        <div className="background-white">

                            {watch('typeTemplate')?.id === 1 &&
                                <>
                                    <Title control={control} watch={watch} setValue={setValue} />
                                    <Editeur control={control} watch={watch} nbCaract={1000} ia={true} variable={true}/>
                                    <Footer control={control} />
                                    <ListButtonAction control={control} setValue={setValue} getValues={getValues} />
                                </>
                            } 

                            {watch('typeTemplate')?.id === 2 &&
                                <>
                                    <Editeur control={control} watch={watch} nbCaract={1000} ia={true} variable={true}/>
                                    <Carousel control={control} watch={watch} setValue={setValue} getValues={getValues} />
                                </>
                            }

                        </div>
                        <FormButtonSubmit loading={loading} label="Créer un template" />
                    </form>
     
                </div>

                <div className="aside-right">
                    <WhatsAppExample watchForm={watchForm} />
                </div>
            </div>
        </div>
    )
}