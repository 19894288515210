import api from '../api';


export const createWorkspace = (data) => api.post('/api/workspace', data)

export const updateWorkspace = (data) => api.put('/api/workspace/' + localStorage.getItem('idWorkspace'), data)

export const getOneWorkspaceByUser = async () => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace'), {});
    return(result.data.workspace);
}