import React, { useEffect, useRef, useCallback } from 'react';
import './popup.scss';
import closeIcon from "../../../assets/images/icons/close.svg";

export const Popup = ({ title, children, closePopup, customContent, top }) => {
    const popupRef = useRef();
    const containerRef = useRef();

    useEffect(() => {
        document.documentElement.classList.add("modal-open");
    }, []);

    const handleClosePopup = useCallback(() => {
        if (popupRef.current) {
            popupRef.current.classList.add("closed");
        }

        setTimeout(() => {
            closePopup();
            document.documentElement.classList.remove("modal-open");
        }, 450);
    }, [closePopup]);

    useEffect(() => {
        function handleClickOutsidePopup(event) {
            if (containerRef.current && !containerRef.current.contains(event.target) && !event.target.closest(".MuiPopper-root")) {
                handleClosePopup();
            }
        }

        document.addEventListener("mousedown", handleClickOutsidePopup);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsidePopup);
        };
    }, [handleClosePopup]);

    return (
        <div className={`popup ${top ? "top" : ""}`} ref={popupRef}>
            <div className='popup-container' ref={containerRef}>
                <div className='closePopupIcon' onClick={handleClosePopup}>
                    <img src={closeIcon} alt="close" />
                </div>

                {title && <h4>{title}</h4>}

                <div className='popup-content'>
                    {!!customContent ? React.Children.map(children, child => {
                        if (React.isValidElement(child))
                            return React.cloneElement(child, { handleClosePopup });

                        return child;
                    }) : children}
                </div>
            </div>
        </div>
    );
};
