import { launchWhatsAppSignup } from './LunchWhatsAppSignup';

export const FacebookLoginButton = () => {
    return (
        <button 
            onClick={launchWhatsAppSignup}
            style={{
                backgroundColor: "#1877f2", 
                border: "0", 
                borderRadius: "4px", 
                color: "#fff", 
                cursor: "pointer", 
                fontFamily: "Helvetica, Arial, sans-serif", 
                fontSize: "16px", 
                fontWeight: "bold", 
                height: "40px", 
                padding: "0 24px"
            }}
        >
            Se connecter avec Meta
        </button>
    );
};
