import api from '../api';
import Cookies from 'js-cookie';

export const signIn = async (data) => {
    return await api.post(`/auth`, data);
}

export const register = async (data) => {
    return await api.post(`/register`, data);
}

export const logOut = async () => {
    return await new Promise((resolve) => {
        Cookies.remove('loggedIn');
        localStorage.removeItem("token");
        localStorage.removeItem("idUser");
        resolve();
    });
}

export const requestPassword = async (data) => {
    return await api.post(`/request-password`, data);
}

export const resetPassword = async (data, token) => {
    return await api.post(`/reset-password/${token}`, data);
}

export const loginUser = async (data) => {
    return await api.post(`/api/user-login`, data);
}

