import React from 'react';


export const ConfirmImport = ({nbContact}) => {

    return (
        <div>
            <h2>Fichier bien importés <br />Contact importés : {nbContact} </h2>
        </div>
  	);
} 