import React, { useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { FormInputText } from "../../../components/UI/Form/FormInputText";

export const ButtonCallActionWebsite = (props) => {
    const { control, index, remove, name, deleteTooltip } = props;

    const handleRemove = useCallback(() => {
        remove(index);
    }, [remove, index]);

    return (
        <div className="buttonCallActionWebsite">
            <div className="containerButton">
                <div className="buttonContainer">
                    <p>Texte du bouton</p>
                    <FormInputText
                        control={control}
                        name={`${name}[${index}].text`}
                        maxLength={25}
                    />
                </div>
                <div className="buttonContainer">
                    <p>URL du site web</p>
                    <FormInputText
                        control={control}
                        name={`${name}[${index}].url`}
                        placeholder="https://example.com"
                        maxLength={2000}
                        rules={{
                            validate: value => value.startsWith('http://') || value.startsWith('https://') || "L'URL doit commencer par http:// ou https://"
                        }}
                    />
                </div>
            </div>
            {deleteTooltip ? (
                <Tooltip title={deleteTooltip}>
                    <div className="deleteBtn" onClick={handleRemove}>
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                </Tooltip>
            ) : (
                <div className="deleteBtn" onClick={handleRemove}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            )}
        </div>
    );
};
