import React, { useState, useEffect } from 'react';
import { getListContactFailed, cleanContact } from '../../../store/index.service';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const CleanContact = ({ handleClosePopup, setRefreshDataGroup, handleGetData }) => {
    const [listContactFailed, setListContactFailed] = useState({loading: true, data: []});
    const [contactFailedSelect, setContactFailedSelect] = useState([]);

    useEffect(() => {
        getListContactFailed().then((res) => {
            setListContactFailed({loading: false, data: res.data});
        });
    }, []);

    const columns = [
        { field: 'firstName', headerName: 'Prénom', flex: 1},
        { field: 'lastName', headerName: 'Nom', flex: 1},
        { field: 'phone', headerName: 'Téléphone', flex: 1}
    ];

    const CleanContact = () => {
        console.log(contactFailedSelect);
        cleanContact(contactFailedSelect).then(() => {
            handleClosePopup();
        });
    }

    return (
        <div className='popupCreateHorseQuick popup-container-full'>

            <h2>Clean des contacts échoués</h2>

            <div className="action">
                <button className="button" onClick={() => CleanContact()}>Blacklister les contacts sélectionnés</button>
            </div>

            <DataGridPro 
                autoHeight 
                rows={listContactFailed.data} 
                columns={columns} 
                pageSize={20} 
                className="table" 
                loading={listContactFailed.loading} 
                checkboxSelection
                disableSelectionOnClick
                onRowSelectionModelChange={(selection) => setContactFailedSelect(selection)}
                pagination
                pageSizeOptions={[20, 50, 100]}
            />

        </div>
    )
}