import './whatsAppExample.scss';
import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { StoreContext } from '../../store/store';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const WhatsAppExample  = ({watchForm, host}) => {
	const { workspace } = useContext(StoreContext);
	const baseUrl = host;
	let img = null;
	let video = null;

    if (watchForm?.image)
        img = typeof watchForm.image !== "string" ? Array.from(watchForm.image)[0] : watchForm.image;
    if (watchForm?.video)
        video = typeof watchForm.video !== "string" ? Array.from(watchForm.video)[0] : watchForm.video;
	
	return (
		<div className="whatsapp-chat">

			<div className="chat-header">
				<p> {workspace.workspaceTitle} </p>
			</div>

			<div className="chat-messages">

				<div className="contentMessage">

					{watchForm?.selectTitle?.id === 2 && <div className="title">{watchForm?.title}</div>}

					{watchForm?.selectTitle?.id === 3 && (
						<div className="image">
							{watchForm?.image && img != null ? (
							<div className="upload-img">
								<img
								src={
									img.type && img.type.includes("image")
									? img.size
										? URL.createObjectURL(img)
										: `${baseUrl}${img.url}`
									: `${baseUrl}${img}`
								}
								alt=""
								/>
							</div>
							) : watchForm?.video && video != null ? (
								<div className="video-container">
									<video controls className="styled-video">
										<source
										src={
											video.type && video.type.includes("video")
											? URL.createObjectURL(video)
											: `${baseUrl}${video.url}`
										}
										type={video.type}
										/>
										Your browser does not support the video tag.
									</video>
								</div>
							) : null}
						</div>
					)}

					<div className="message" dangerouslySetInnerHTML={{ __html: watchForm?.content }}></div>
					<div className="footer">{watchForm?.footer}</div>
				</div>

				<div className="carrousel">
					<Swiper spaceBetween={5} slidesPerView={1.25}>
						{watchForm?.carouselItems?.map((button, index) => (
							<SwiperSlide>
								<div className="carrousel-item">
									<img
										src={
											button.media.type && button.media.type.includes("image") ?
											button.media.size ?
													URL.createObjectURL(button.media)
												:
													`${baseUrl}${button.media.url}`
											: `${baseUrl}${button.media}`
										}
										alt=""
									/>
									<div className="carrousel-content" dangerouslySetInnerHTML={{ __html: button?.content }}></div>
									<div className="actions">
										{button?.listButtons?.map((button, index) => (
											button.type === "phone" ? (
												<div className="button" key={index}>
													<a href={`tel:${button?.phoneNumber}`} target="_blank" rel="noreferrer">
														<FontAwesomeIcon icon={faPhone} />
														{button?.text}
													</a>
												</div>
											) : (
												<div className="button" key={index}>
													<a href={button?.url} target="_blank" rel="noreferrer">
														<FontAwesomeIcon icon={faExternalLinkAlt} />
														{button?.text}
													</a>
												</div>
											)
										))}
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				<div className="button-list">

					{watchForm?.listButtons?.map((button, index) => {
						if (button.type === "web") {
							return (
							<div className="button" key={index}>
								<a href={button?.url} target="_blank" rel="noreferrer">
								<FontAwesomeIcon icon={faExternalLinkAlt} />
								{button?.text}
								</a>
							</div>
							);
						} else if (button.type === "phone") {
							return (
							<div className="button" key={index}>
								<a href={`tel:${button?.phoneNumber}`} target="_blank" rel="noreferrer">
								<FontAwesomeIcon icon={faPhone} />
								{button?.text}
								</a>
							</div>
							);
						} else if (button.type === "stopMarket") {
							return (
								<div className="button" key={index}>
									Arretez les promotions
								</div>
							);
						} else {
							return null;
						}
					})}

				</div>
				
			</div>
		</div>
	);
};
