import './personalInfo.scss';
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import { NavigationAccount } from "../../../components/NavigationAccount/NavigationAccount";
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { getOneUser, updateUser } from '../../../store/index.service';
import { Toastify } from "../../../components/UI/Toastify/Toastify";

export const PersonalInfo = () => {
    const { handleSubmit: handleSubmitInfo, control: controlInfo, setValue: setValueInfo } = useForm();
    const { handleSubmit: handleSubmitPassword, control: controlPassword } = useForm();
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState(null);

    useEffect(() => {
        getOneUser()
            .then(res => {
                setValueInfo('firstName', res.firstName);
                setValueInfo('lastName', res.lastName);
                setValueInfo('phone', res.phone);
                setValueInfo('country', res.country);
            })
    }, [setValueInfo]);

    const onSubmitInfo = async (data) => {
        setLoadingInfo(true);
        await updateUser(data);
        Toastify("updateProfil", "success");
        setLoadingInfo(false);
    }

    const onSubmitPassword = async (data) => {
        setLoadingPassword(true);
        
        if (data.password !== data['password-confirm']) {
            setErrorPassword('Les mots de passe ne correspondent pas');
            setLoadingPassword(false);
            return;
        }

        await updateUser(data);

        setLoadingPassword(false);
    }

    return (
        <div className="personal-info">
            <NavigationAccount active="profile" />

            <div className="personal-info-content">
                <div className="title">
                    <h3>Information personnelle</h3>
                </div>

                <div className="form">
                    <form onSubmit={handleSubmitInfo(onSubmitInfo)}>
                        <div className="form-group">
                            <FormInputText name="firstName" control={controlInfo} rules={{ required: true }} label="Prénom" type="text" />
                            <FormInputText name="lastName" control={controlInfo} rules={{ required: true }} label="Nom" type="text" />
                        </div>
                        <div className="form-group">
                            <FormInputText name="phone" control={controlInfo} rules={{ required: false }} label="Téléphone" type="number" />
                            <FormInputText name="country" control={controlInfo} rules={{ required: false }} label="Pays" type="text" />
                        </div>

                        <div className="button">
                            <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                                <ClipLoader color={"#fff"} loading={loadingInfo} size={20} cssOverride={{ marginRight: 5 }} />
                                Enregistrer
                            </Button>
                        </div>

                    </form>
                </div>

            </div>

            <div className="personal-info-content">
                <div className="title">
                    <h3>Mot de passe</h3>
                </div>
                <div className="form">
                    <div className="error">{errorPassword}</div>
                    <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
                        <div className="form-group">
                            <FormInputText name="password" control={controlPassword} rules={{ required: true }} label="Mot de passe" type="password" />
                        </div>
                        <div className="form-group">
                            <FormInputText name="password-confirm" control={controlPassword} rules={{ required: true }} label="Confirmation mot de passe" type="password" />
                        </div>

                        <div className="button">
                            <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                                <ClipLoader color={"#fff"} loading={loadingPassword} size={20} cssOverride={{ marginRight: 5 }} />
                                Enregistrer
                            </Button>
                        </div>

                    </form>
                </div>
            </div>

        </div>
  	);
} 