import './listWorkspace.scss';
import { useState, useRef, useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from "react-spinners/ClipLoader";
import { Button } from '@mui/material';
import { StoreContext } from '../../../store/store';
import { Popup } from '../../UI/Popup/Popup';
import { FormInputText } from "../../UI/Form/FormInputText";
import { createWorkspace } from '../../../store/index.service';

export const ListWorkspace = () => {
    const [dropdownWorkspace, setDropdownWorkspace] = useState(false);
    const [popupCreateWorkspace, setPopupCreateWorkspace] = useState(false);
    const dropdownRef = useRef(null);
    const { workspace, listWorkspace, setWorkspace } = useContext(StoreContext);
    const { handleSubmit, control } = useForm();
    const [loading, setLoading] = useState(false);

    const toggleDropdownWorkspace = () => {
        setDropdownWorkspace(!dropdownWorkspace);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownWorkspace(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openCreateWorkspacePopup = () => {
        setDropdownWorkspace(false);
        setPopupCreateWorkspace(true);
    };

    const handleChangeWorkspace = (workspace) => {
        setWorkspace(workspace);
        localStorage.removeItem('idWorkspace');
        localStorage.setItem('idWorkspace', workspace.workspaceId);
        window.location.reload();
    };

    const onSubmit = async (data) => {
        setLoading(true);
        createWorkspace(data)
            .then(res => {
                setPopupCreateWorkspace(false);
                setWorkspace(res.data.workspace);
                localStorage.removeItem('idWorkspace');
                localStorage.setItem('idWorkspace', res.data.workspace.workspaceId);
                window.location.reload();
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className="workspace-container" ref={dropdownRef}>
            <div className={dropdownWorkspace ? "workspace active" : "workspace"} onClick={toggleDropdownWorkspace}>
                <p>{workspace.workspaceTitle}</p>
                <FontAwesomeIcon icon={faChevronDown} />
            </div>

            {dropdownWorkspace &&
                <div className="list-workspace">
                    {listWorkspace
                        ?.filter((el) => el.workspaceId !== workspace.workspaceId)
                        .map((el, index) => (
                            <div className="one-workspace" onClick={() => handleChangeWorkspace(el)}>
                                <p>{el.workspaceTitle}</p>
                            </div>
                        )
                    )}
                    <div className="one-workspace add-workspace" onClick={openCreateWorkspacePopup}>
                        <FontAwesomeIcon icon={faPlus} />
                        <p>Ajouter un espace de travail</p>
                    </div>
                </div>
            }

            {popupCreateWorkspace && 
                <Popup closePopup={() => setPopupCreateWorkspace(false)} title="Créer un espace de travail">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormInputText name="workspaceName" control={control} rules={{ required: true }} label="Nom de votre espace" type="text" />
                        <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                            <ClipLoader color={"#fff"} loading={loading} size={20} cssOverride={{ marginRight: 5 }} />
                            Créer
                        </Button>
                    </form>
                </Popup>
            }
        </div>
    );
};
