import './navigation.scss';
import iconSend from '../../assets/images/icons/icon-send.svg';
import iconParameters from '../../assets/images/icons/icon-parameters.svg';
import iconSupport from '../../assets/images/icons/icon-support.svg';
import iconFaq from '../../assets/images/icons/icon-faq.svg';
import iconContact from '../../assets/images/icons/icon-contact.svg';
import iconTemplate from '../../assets/images/icons/icon-template.svg';
import iconEmail from '../../assets/images/icons/icon-email.svg';
import iconHome from '../../assets/images/icons/icon-home.svg';
import { useContext } from 'react';
import { ListWorkspace } from '../DropDown/ListWorkspace/ListWorkspace';
import { StoreContext } from '../../store/store';
import { NavLink } from './NavLink';
import { useLocation } from 'react-router-dom';

export const Navigation = () => {
    const { nbMessageNotRead, workspace, isTrialOffer } = useContext(StoreContext);
    const tutoStepNumber = parseInt(workspace?.workspaceTutoStep);
    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    return (
        <nav className={isTrialOffer ? 'isTrial' : ''}>

            <div className="logo-container">
                <div className="logo">
                    <div className="logo"></div>
                </div>
            </div>

            <ListWorkspace />

            <div className="main-nav">
                <ul>
                    <NavLink 
                        path="/dashboard" 
                        icon={iconHome} 
                        text="Tableau de bord" 
                        alt="icon home"
                        isActive={isActive('/dashboard')}
                    />

                    <NavLink 
                        path="/messagerie" 
                        icon={iconEmail} 
                        text="Messagerie" 
                        alt="icon messaging" 
                        badge={nbMessageNotRead} 
                        restricted={tutoStepNumber < 5 || workspace?.feature === null} 
                        isActive={isActive('/messagerie')}
                    />

                    <NavLink
                        path="/campagnes"
                        icon={iconSend}
                        text="Campagnes"
                        alt="icon send"
                        restricted={tutoStepNumber < 4 || workspace?.feature === null}
                        isActive={isActive('/campagnes')}
                    />

                    <NavLink
                        path="/contact"
                        icon={iconContact}
                        text="Contact"
                        alt="icon contact"
                        restricted={tutoStepNumber < 3 || workspace?.feature === null}
                        isActive={isActive('/contact')}
                    />

                    <NavLink
                        path="/template"
                        icon={iconTemplate}
                        text="Template"
                        alt="icon template"
                        restricted={tutoStepNumber < 2 || workspace?.feature === null}
                        isActive={isActive('/template')}
                    />
                </ul>
            </div>

            <div className="main-nav settings-nav">
                <ul>
                    <NavLink
                        path="/faq"
                        icon={iconFaq}
                        text="FAQ"
                        alt="icon faq"
                        isActive={isActive('/faq')}
                    />

                    <NavLink
                        path="/faq"
                        icon={iconSupport}
                        text="Support"
                        alt="icon support"
                        isActive={isActive('/faq')}
                    />

                    <NavLink
                        path="/compte/profile"
                        icon={iconParameters}
                        text="Paramètres"
                        alt="icon parameters"
                        isActive={isActive('/compte/profile')}
                    />
                </ul>
            </div>
        </nav>
    );
}
