import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { getKpiDashboard } from '../../../store/index.service';

export const KpiWorkspace = () => {
    const [kpiDashboardCampaign, setKpiDashboardCampaign] = useState([]);
    const chartRef = useRef();
    const [chart, setChart] = useState(null);

    useEffect(() => {
        getKpiDashboard().then((result) => {
            setKpiDashboardCampaign(result.data);
        });
    }, []);

    useEffect(() => {
        if (!chart && kpiDashboardCampaign.nbSend) {
            const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
            
            const labels = months.map((month, index) => month.substr(0, 3)); // Utiliser uniquement les trois premières lettres des mois
    
            const nbSendData = kpiDashboardCampaign.nbSend.map(data => data.messageCount);
            const nbDelivredData = kpiDashboardCampaign.nbDelivred.map(data => data.messageCount);
            const nbFailedData = kpiDashboardCampaign.nbFailed.map(data => data.messageCount);
            const nbReadData = kpiDashboardCampaign.nbRead.map(data => data.messageCount);
    
            setChart(new Chart(chartRef.current, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        label: "Nombre d'envoi",
                        data: nbSendData,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    },
                    {
                        label: "Nombre de messages délivrés",
                        data: nbDelivredData,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    },
                    {
                        label: "Nombre de messages échoués",
                        data: nbFailedData,
                        backgroundColor: 'rgba(255, 206, 86, 0.2)',
                        borderColor: 'rgba(255, 206, 86, 1)',
                        borderWidth: 1
                    },
                    {
                        label: "Nombre de messages lus",
                        data: nbReadData,
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    }
                ]},
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            }));
        }
    }, [chart, kpiDashboardCampaign]);
    

    return (
        <div className="stats">
            <canvas ref={chartRef}></canvas>
        </div>
    );
}