import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

export const NavLink = (props) => {
    const { path, icon, text, alt, badge, restricted, isActive } = props;

    return (
        <Link to={path} className={isActive ? 'active' : ''}>
            <li>
                <img src={icon} alt={alt} />
                <span>{text}</span>
                {badge > 0 && <span className="badge">{badge}</span>}
                {restricted && <FontAwesomeIcon icon={faLock} className="restricted" />}
            </li>
        </Link>
    );
}
