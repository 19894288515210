
import './reportBug.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export const ReportBug  = () => {

	return (
        <div className="report-bug">
            <Link to="https://forms.monday.com/forms/8d91b314f49b78a2b75174c9e211939f?r=use1" target="_blank">
                <div className="icon">
                    <FontAwesomeIcon icon={faBug} size="3x" />
                </div>
                Report a bug
            </Link>
        </div>
	);
};
