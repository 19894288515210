
import React from 'react';
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { FormAutocomplete } from "../../../components/UI/Form/FormAutocomplete";

export const Base  = (props) => {
    const { control } = props;

    const typeTemplate = [
        { id: 1, name: "Standard" },
        { id: 2, name: "Carrousel" }
    ];

	return (
        <div className="base">
            <div className="form-group">
                <FormInputText name="name" control={control} rules={{ required: true }} label="Nom du template ( meta ) *" />
                
                <FormAutocomplete
                    name="typeTemplate"
                    control={control}
                    label="Type de template *"
                    options={typeTemplate}
                    rules={{ required: true }}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option) =>
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    }
                    clearable={true}
                /> 
                
            </div>
        </div>
	);
};
