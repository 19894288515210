import React from "react";
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const FormInputText = (props) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const {
        name,
        control,
        label,
        rules,
        type = "text",
        inputProps,
        endAdornment,
        helperText,
        disabled,
        maxLength,
        placeholder
    } = props;

    const modifiedInputProps = {
        ...inputProps,
        maxLength: maxLength
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    label={label}
                    fullWidth
                    size="small"
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : helperText}
                    type={type === "password" && showPassword ? "text" : type}
                    value={value ?? ""}
                    inputProps={modifiedInputProps}
                    InputProps={{
                        endAdornment: type === "password" && (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                />
            )}
        />
    );
};
