import React from 'react'; // Assurez-vous d'importer React
import { toast, Bounce } from 'react-toastify';

const CustomToast = ({ name, newData }) => {
    return (
        <div>
            {(() => {
                switch (name) {
                    case 'metaConnected':
                        return (
                            <div>
                                <p>Meta connecté</p>
                            </div>
                        );
                    case 'createTemplate':
                        return (
                            <div>
                                <p>Template créé</p>
                                <p>Nom du template : {newData?.name}</p>
                                <p>Il faut maintenant attendre son approbation par Meta (quelques heures).</p>
                            </div>
                        );
                    case 'deleteTemplate':
                        return (
                            <div>
                                <p>Template supprimé</p>
                            </div>
                        );
                    case 'createCampaign':
                        return (
                            <div>
                                <p>Campagne créée</p>
                                <p>Nom de la campagne : {newData?.name}</p>
                            </div>
                        );
                    case 'updateTemplate':
                        return (
                            <div>
                                <p>Campagne mis à jour</p>
                                <p>Nom de la campagne : {newData?.name}</p>
                            </div>
                        );
                    case 'createContact':
                        return (
                            <div>
                                <p>Contact créé</p>
                            </div>
                        );
                    case 'updateContact':
                        return (
                            <div>
                                <p>Contact modifié</p>
                            </div>
                        );
                    case 'deleteContact':
                        return (
                            <div>
                                <p>Contact supprimé</p>
                            </div>
                        );
                    case 'createGroups':
                        return (
                            <div>
                                <p>Groupe créé</p>
                            </div>
                        );
                    case 'updateGroups':
                        return (
                            <div>
                                <p>Groupe modifié</p>
                            </div>
                        );
                    case 'deleteGroups':
                        return (
                            <div>
                                <p>Groupe supprimé</p>
                            </div>
                        );
                    case 'toMuchText':
                        return (
                            <div>
                                <p>Vous avez trop de texte</p>
                            </div>
                        );
                    case 'emptyText':
                        return (
                            <div>
                                <p>Vous n'avez pas mis de texte dans votre message.</p>
                            </div>
                        );
                    case 'errorTemplateName':
                        return (
                            <div>
                                <p>Le nom du template existe déjà, veuillez en choisir un autre.</p>
                            </div>
                        );
                    case 'memberChangeRole':
                        return (
                            <div>
                                <p>Role modifié</p>
                            </div>
                        );
                    case 'removeMemberInWorkspace':
                        return (
                            <div>
                                <p>Membre supprimé</p>
                            </div>
                        );
                    case 'updateWorkspace':
                        return (
                            <div>
                                <p>Espace de travail modifié</p>
                            </div>
                        );
                    case 'updateProfil':
                        return (
                            <div>
                                <p>Profile modifié</p>
                            </div>
                        );
                    case 'createField':
                        return (
                            <div>
                                <p>Colonne créée</p>
                            </div>
                        );
                    default:
                        return (
                            <div>
                                <h3>Une erreur est survenue</h3>
                            </div>
                        );
                }
            })()}
        </div>
    );
};

export const Toastify = (name, type = "success", data) => {
    toast[type](<CustomToast name={name} newData={data} />, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
};
