import './dashboard.scss';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tutorial } from '../../components/Tutorial/Tutorial';
import { Kpi } from '../../components/Kpi/Kpi';
import { Info } from '../../components/Info/Info';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { StoreContext } from '../../store/store';
import { joinWorkspace } from '../../store/index.service';

export const Dashboard = () => {
    const { t } = useTranslation();
    const { workspace } = useContext(StoreContext);
    const tutoStepNumber = parseInt(workspace?.workspaceTutoStep);
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();

    // Vérifier si le chemin contient "invit/" suivi d'un token
    const match = path.match(/\/invit\/([a-zA-Z0-9]+)/);

    const handleClickOpenChat = () => {
        window.$crisp.push(["do", "chat:open"]);
    }

    if (match) {
        const token = match[1];
        joinWorkspace(token).then(res => {
            navigate('/dashboard');
        });
    }


    return (
        <div className="dashboard">

            <h1>Bonjour, {workspace?.workspaceTitle}</h1>

            {tutoStepNumber !== 5 && <Tutorial />}

            <div className="contents">
                <Kpi />
                <div className="aside-right">
                    <Info title={t('info_faq_title')} content={t('info_faq_content')} btnTitle={t('info_faq_btn_title')} btnLink="/faq" />
                    <Info title={t('info_support_title')} content={t('info_support_content')} btnTitle={t('info_support_btn_title')} onClick={handleClickOpenChat} />
                </div>
            </div>
        </div>
    )
}