import './privacyPolicy.scss';

export const LegalMention = () => {

    return (
        <div className="politique">
            <h1>Mentions Légales de Whakup</h1>

            <h3>1. Éditeur du Site</h3>
            <p>Le site Whakup est édité par la société UXIA, société par actions simplifiée au capital de 10000, dont le siège social est situé au 49 RUE DE PONTHIEU, 75008 PARIS, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro Siren 848276135.</p>
            <h3>2. Directeur de la Publication</h3>
            <p>Le Directeur de la publication est Arthur Lyonnet, en sa qualité de Président.</p>
            <h3>3. Hébergement du Site</h3>
            <p>Le site Whakup est hébergé par OVH, dont le siège social est situé à 2 rue Kellermann - 59100 Roubaix - France.</p>
            <h3>4. Propriété Intellectuelle</h3>
            <p>Tous les éléments du site Whakup, y compris les textes, graphiques, logos, sons, photos, animations, sont la propriété exclusive de la société UXIA, à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.</p>
            <h3>5. Responsabilité</h3>
            <p>La société UXIA ne peut être tenue responsable des dommages directs ou indirects causés au matériel de l'utilisateur, lors de l'accès au site Whakup. Des informations mises à jour et exactes sont fournies, mais des erreurs ou omissions peuvent survenir.</p>
            <h3>6. Données Personnelles</h3>
            <p>La collecte et le traitement des données personnelles sur le site Whakup sont régis par notre Politique de Confidentialité.</p>
            <h3>7. Loi Applicable et Juridiction</h3>
            <p>Les présentes mentions légales sont soumises au droit français. En cas de litige, les tribunaux français seront seuls compétents.</p>
            <h3>8. Contact</h3>
            <p>Pour toute question relative aux mentions légales du site, veuillez contacter arthurlyonnet@uxia.com.</p>
        </div>
    )
}