import api from '../api';

export const getListTemplate = async () => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/template' , {});
    return(result.data);
}

export const getOneTemplate = async (id) => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/template/' + id , {});
    return(result.data);
}

export const createTemplate = (data) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/template', data)

export const updateTemplate = (data, id) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/template' + id, data)

export const deleteTemplate= (id) => api.delete('/api/workspace/' + localStorage.getItem('idWorkspace') + '/template/' + id, {})