import { useState, createContext } from 'react';
import client from './api';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    const [lang, setLang] = useState("fr");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
    
    const [user, setUser] = useState({});
    const [workspace, setWorkspace] = useState({});
    const [listWorkspace, setListWorkspace] = useState([]);
    const [nbMessageNotRead, setNbMessageNotRead] = useState(0);
    const [isTrialOffer, setIsTrialOffer] = useState(false);

    client.interceptors.request.use(
        (config) => {
          if (localStorage.getItem('token')) {
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            };
          }
          return config;
        },
        (error) => Promise.reject(error),
    );

    client.interceptors.response.use((response) => response, (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
            setLoggedIn(false);
            localStorage.removeItem("token");
        }
        return Promise.reject(error);
      });

    return (
        <StoreContext.Provider value={{
            lang, setLang,
            isMenuOpen, setIsMenuOpen,
            loggedIn, setLoggedIn,
            user, setUser,
            workspace, setWorkspace,
            listWorkspace, setListWorkspace,
            nbMessageNotRead, setNbMessageNotRead,
            isTrialOffer, setIsTrialOffer
        }}>
            { children }
        </StoreContext.Provider>
    );
}