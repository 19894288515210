import './subscription.scss';
import { NavigationAccount } from "../../../components/NavigationAccount/NavigationAccount";
import { PricingSubscription } from "../../../components/PricingSubscription/PricingSubscription";
import { useContext } from 'react';
import { StoreContext } from "../../../store/store";


export const Subscription = () => {
    const { workspace } = useContext(StoreContext);
    const paid = workspace?.subscription?.paid ?? false;
    const plan = workspace?.subscription?.plan ?? "Plan gratuit";
    const nextPaymentDate = workspace?.subscription?.endDate.date ?? "Aucun";
    const amount = workspace?.subscription?.planAmount ?? "0";

    const handleStopSubscription = () => {
        console.log('Stop subscription');
    }

    return (
        <div className="subscription">
            <NavigationAccount active="subscription" />

            <div className="subscription-content">
                <div className="myPlan backgroundWhite">
                    <div className="myPlanHeader">
                        <h2>Votre abonnement : </h2>
                        <span className={paid ? 'paid' : 'free'}>{paid ? 'Abonnement actif' : 'Abonnement inactif'}</span>
                        {paid && (
                            workspace?.workspaceRole === 'admin' && <button className="btn" onClick={handleStopSubscription}>Stop abonnement</button>
                        )}
                    </div>
                    {paid && ( 
                        <div className="myPlanContent">
                            <div className="myPlanContentItem">
                                <p>Plan : {plan}</p>
                            </div>
                            <div className="myPlanContentItem">
                                <p>Prochain paiement le {new Date(nextPaymentDate).toLocaleDateString()}</p>
                            </div>
                            <div className="myPlanContentItem">
                                <p>Montant : {(amount / 100)}€</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <PricingSubscription />

        </div>
    )
}