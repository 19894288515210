import './invoice.scss';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { NavigationAccount } from "../../../components/NavigationAccount/NavigationAccount";
import { getListInvoice } from '../../../store/index.service';

export const Invoice = () => {
    const [listInvoice, setListInvoice] = useState({loading: true, data: []});

    useEffect(() => {
        getListInvoice().then(res => {
            setListInvoice({loading: false, data: res ?? []});
        });
    }, []);

    const columns = [
        { field: 'companyName', headerName: 'Entreprise', flex: 1.5 },
        { field: 'invoiceNumber', headerName: 'Numéro de facture', flex: 1.5 },
        { field: 'amountPaid', headerName: 'Montant payé', flex: 1, type: 'number'},
        { field: 'currency', headerName: 'Devise', flex: 1 },
        { field: 'paid', headerName: 'Payé', flex: 1, type: 'boolean', valueGetter: ({ value }) => value ? 'Oui' : 'Non' },
        { field: 'createdAt', headerName: 'Crée le', flex: 1, type: 'dateTime', flex: 1, valueGetter: (params) => { return params ? new Date(params.date) : null;} },
        { field: 'actions', type: "actions", flex: 0.4, getActions: (params) => [
            <Link to={params.row.invoicePdf} target="_blank">
                <GridActionsCellItem icon={<FontAwesomeIcon icon={faFilePdf} size="1x" />} label="Facture" />
            </Link>
        ]}
    ];

    return (
        <div className="invoice">
            <NavigationAccount active="invoice" />

            <DataGridPro 
                autoHeight 
                rows={listInvoice.data} 
                columns={columns} 
                pageSize={20} 
                className="table" 
                loading={listInvoice.loading} 
                pagination
                pageSizeOptions={[20, 50, 100]}
            />
        </div>
    )
}