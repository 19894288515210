import './groups.scss';
import iconUpdate from '../../../assets/images/icons/icon-edit.svg';
import iconRemove from '../../../assets/images/icons/icon-trash.svg';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ContactContext } from '../../../store/contactContext';
import { getListGroups, getAllListContact, removeGroup, createGroup, updateGroup } from '../../../store/index.service';
import { ConfirmDelete } from "../../UI/Popup/ConfirmDelete";
import { Popup } from "../../UI/Popup/Popup";
import { Toastify } from "../../UI/Toastify/Toastify";
import { StoreContext } from '../../../store/store';

export const Groups = () => {
	const { workspace } = useContext(StoreContext);
	const { idGroup, setIdGroup, setNameGroup, refreshDataGroup, setRefreshDataGroup, setListGroup } = useContext(ContactContext);
	const [listGroups, setListGroups] = useState([]);
	const [countAllContact, setCountAllContact] = useState("");
	const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
	const { t } = useTranslation();
	const [editingGroupId, setEditingGroupId] = useState(null);
	const [createGroupId, setCreateGroupId] = useState(null);

	useEffect(() => {
		getAllListContact().then(res => {
			setCountAllContact(res.data.contact);
		});
		
		getListGroups().then(res => {
			setListGroups(res);
			setListGroup(res);
		});

		setIdGroup("default");
		setNameGroup("Tous les contacts");
		setRefreshDataGroup(false);
	}, [refreshDataGroup]);

	const handleChangeGroup = (id, name) => {
		setIdGroup(id);
		setNameGroup(name);
	}

	const handleCreateGroup = () => {
		setCreateGroupId(true);
	}

	const handleUpdate = (idGroup) => {
		setEditingGroupId(idGroup);
	}

	const saveGroupName = (idGroup, title) => {
		setEditingGroupId(null);

		updateGroup({title}, idGroup).then(res => {
			getListGroups().then(res => {
				Toastify("updateGroups", "success");
				setListGroups(res);
				setListGroup(res);
			});
		});
	}

	const handleDelete = () => {
		removeGroup(showPopupConfirmDelete.id).then(res => {
			getListGroups().then(res => {
				Toastify("deleteGroups", "success");
				setListGroups(res);
				setListGroup(res);
			});
		});
		setShowPopupConfirmDelete({show: false});
	}

	return (
		<div className="groups">

			<div className="list-group">

				{/* Tous les contacts */}
				<div className={idGroup === "default" ? "one-group active" : "one-group"} onClick={() => handleChangeGroup("default", "Tous les contacts")}>
					<div className="info-group">
						<div className="text-group">
							<div className="title-group">
								Tous les contacts
							</div>
							<div className="count-contact">
								{countAllContact > 1 ? countAllContact + " contacts" : countAllContact + " contact"}
							</div>
						</div>
					</div>
				</div>

				{/* Liste des groupes */}
				{listGroups.map((group, index) => (
					<div className={idGroup === group.id ? "one-group active" : "one-group"} key={index} onClick={() => handleChangeGroup(group.id, group.title)}>
						<div className="info-group">
							<div className="text-group">
								{editingGroupId === group.id ? (
									<input
										type="text"
										defaultValue={group.title}
										onBlur={(e) => saveGroupName(group.id, e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												e.target.blur();
											}
										}}
										autoFocus
									/>
								) : (
									<div className="title-group" onClick={() => handleCreateGroup()}>
										{group.title}
									</div>
								)}
								<div className="count-contact">
									{group.nbContact > 1 ? group.nbContact + " contacts" : group.nbContact + " contact"}
								</div>
							</div>
							{workspace?.workspaceRole === 'admin' &&
								<div className="action-group">
									<div className="icon-action add" onClick={() => handleUpdate(group.id)}>
										<img src={iconUpdate} alt="icon update" />
									</div>
									<div className="icon-action delete" onClick={() => setShowPopupConfirmDelete({ show: true, id: group.id })}>
										<img src={iconRemove} alt="icon remove" />
									</div>
								</div>
							}
						</div>
					</div>
				))}

				{/* Ajouter un groupe */}
				{createGroupId && (
					<div className="one-group add-group">
						<input
							type="text"
							placeholder="Nom du groupe"
							onBlur={(e) => {
								setCreateGroupId(false);

								if (e.target.value === "") return;

								createGroup({title: e.target.value}).then(res => {
									getListGroups().then(res => {
										Toastify("createGroups", "success");
										setListGroups(res);
										setListGroup(res);
									});
								});
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									e.target.blur();
								}
							}}
							autoFocus
						/>
					</div>
				)}

				{/* Ajouter un groupe */}
				{workspace?.workspaceRole === 'admin' &&
					<div className="one-group add-group" onClick={() => handleCreateGroup()}>
						<FontAwesomeIcon icon={faPlus} />
						Ajouter un groupe
					</div>
				}

			</div>
            { showPopupConfirmDelete.show &&
                <Popup closePopup={() => setShowPopupConfirmDelete({ show: false })} customContent={true}>
                    <ConfirmDelete msg={t('campaign_popup_delete')} handleDelete={handleDelete} />
                </Popup>
            }
		</div>
	);
};

