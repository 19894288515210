import React, { useState, useRef } from "react";
import { Icon } from "../Icon/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const attachButtons = [
    { icon: "attachImage", label: "Choose image" }
];

export const ChatInput = ({showAttach, setShowAttach, showEmojis, setShowEmojis, newMessage, setNewMessage, submitNewMessage}) => {
    const textareaRef = useRef(null);
    const [maxHeightReached, setMaxHeightReached] = useState(false);

    const detectEnterPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            setNewMessage((prevMessage) => prevMessage + "\n");
        }
    };

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;

        const maxHeight = parseInt(window.getComputedStyle(textarea).maxHeight, 10);

        if (textarea.scrollHeight > maxHeight) {
            setMaxHeightReached(true);
        } else {
            setMaxHeightReached(false);
        }

        // Scroll automatique vers le bas si le textarea a atteint sa hauteur maximale
        if (textarea.scrollHeight > textarea.clientHeight) {
            textarea.scrollTop = textarea.scrollHeight - textarea.clientHeight;
        }
    };

    const handleSendMessage = () => {
        submitNewMessage();
        setNewMessage("");
        adjustTextareaHeight();
        const textarea = textareaRef.current;
        textarea.style.height = "40px";
    };

    return (
        <div className="chat__input-wrapper">

            {/* Action Button */}
			{/* 
            <div className="pos-rel">
                
                <button aria-label="Attach" onClick={() => setShowAttach(!showAttach)}>
                    <Icon 
                        id="attach"
                        className={`chat__input-icon ${
                            showAttach ? "chat__input-icon--pressed" : ""
                        }`}
                    />
                </button>

                <div className={`chat__attach ${showAttach ? "chat__attach--active" : ""}`}>
                    
                    {attachButtons.map((btn) => (
                        <button className="chat__attach-btn" aria-label={btn.label} key={btn.label}>
                            <Icon id={btn.icon} className="chat__attach-icon" />
                        </button>
                    ))}
                </div>
            </div>
			*/}

            {/* Emoji Button et autres */}
            <textarea
                ref={textareaRef}
                className={`chat__input ${maxHeightReached ? "chat__input--max-height-reached" : ""}`}
                placeholder="Type a message"
                value={newMessage}
                onChange={(e) => { setNewMessage(e.target.value); adjustTextareaHeight(); }}
                onKeyDown={detectEnterPress}
                onKeyUp={adjustTextareaHeight}
            />
            <button aria-label="Send" onClick={handleSendMessage}>
                <Icon id="send" className="chat__input-icon" />
            </button>
        </div>
    );
};
