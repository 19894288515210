import './resetPassword.scss';
import media from '../../../assets/images/WhatsApp-marketing-campaign-1.jpeg';
import logoGreen from '../../../assets/images/logo-green.svg';

import { useState } from 'react';
import { useForm } from "react-hook-form";
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { Button } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from 'react-i18next';
import { resetPassword } from "../../../store/index.service";
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';


export const ResetPassword = () => {
    const { handleSubmit, control } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const { t } = useTranslation();
    const { token } = useParams();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        setLoading(true);

        if (data.password !== data['password-confirm']) {
            setError('password-different');
            setLoading(false);
            return;
        }

        resetPassword(data, token)
            .then(() => {
                navigate('/login');
                setLoading(false);
                setError(false);
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    }

    return (
        <div className="reset-password">

            <div className="login-media">
                <img src={media} alt="media" />
            </div>

            <div className="login-form">
                <div className="logo-whakup">
                    <img src={logoGreen} alt="logo" />
                </div>

                <div className="title">
                    <h1>Changement de mot de passe</h1>
                </div>

                <div className="form">
                    <form name="login" className="register-form" onSubmit={handleSubmit(onSubmit)}>

                        { error &&
                            <div className='error-msg'>
                                <span>{error === 'password-different' ? 'les mots de passe ne correspondent pas' : 'Une erreur est survenu'}</span>
                            </div>
                        }

                        <div className="form-group">
                            <FormInputText name="password" control={control} rules={{ required: true }} label="Mot de passe" type="password" />
                        </div>
                        <div className="form-group">
                            <FormInputText name="password-confirm" control={control} rules={{ required: true }} label="Confirmer votre Mot de passe" type="password" />
                        </div>

                        <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                            <ClipLoader color={"#fff"} loading={loading} size={20} cssOverride={{ marginRight: 5 }} />
                            Envoyer
                        </Button>

                    </form>
                </div>

            </div>
        </div>
  	);
} 