import * as React from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Controller } from "react-hook-form";

export const FormPhoneInputWithCountry = (props) => {
  const {
    group,
    title,
    name,
    control,
    label,
    rules,
    type = "select"
  } = props;

  return (
     <Controller
        name="phone"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className="input-phone">
            <PhoneInput
              country={'fr'}
              value={value}
              onChange={onChange}
              inputStyle={{ width: '100%' }}
            />
            {error && <span style={{ color: 'red' }}>Ce champ est requis</span>}
          </div>
        )}
      />

      
  );
};
