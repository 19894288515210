import { FormInputText } from "../../../components/UI/Form/FormInputText";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

export const Footer  = (props) => {
    const { control } = props;

    const checkEmoji = (value) => {
        const regex = /[\p{Emoji}]/gu;
        return !regex.test(value);
    };

	return (
        <div className="footer">

            <div className="info-form">
                <p className="title">
                    Pied de page <span>Facultatif</span> 
                    <Tooltip title="Les emojis ne sont pas acceptés">
                        <InfoIcon style={{ marginLeft: 10, fontSize: 'medium', verticalAlign: 'middle' }} />
                    </Tooltip>
                </p>
            </div>
            
            <div className="form-group">
                <FormInputText 
                    name="footer" 
                    control={control} 
                    maxLength={60} 
                    rules={{ validate: value => checkEmoji(value) || "N'ajoutez pas d'émojis dans la zone de texte Pied de page", required: false}}
                />
            </div>
            
        </div>
	);
};
