import api from '../api';

export const getListConversation = async () => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/conversation' , {});
    return(result.data);
}

export const getOneConversation = async (id) => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/conversation/' + id, {});
    return(result.data);
}

export const sendMessage = async (id, message) => {
    const result = await api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/conversation/' + id, {
        message: message
    }, {});
    return(result.data);
}

export const sendReadMessage = async (id) => {
    const result = await api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/conversation/' + id + '/read', {}, {});
    return(result.data);
}

export const sendUnReadMessage = async (id) => {
    const result = await api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/conversation/' + id + '/unread', {}, {});
    return(result.data);
}

export const getCountMessageNotRead = async () => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/messaging/not-read', {});
    return(result.data);
}