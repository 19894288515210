import { useEffect } from 'react';

export const Crisp = () => {
	useEffect(() => {
		if (!window.$crisp) {
			window.$crisp = [];
			window.CRISP_WEBSITE_ID = "64ceb171-3d73-427f-aaa4-a4545f11361d";

			(function() {
				var d = document;
				var s = d.createElement("script");

				s.src = "https://client.crisp.chat/l.js";
				s.async = 1;
				d.getElementsByTagName("head")[0].appendChild(s);
			})();
		}
	}, []);

	return null;
};
