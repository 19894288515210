import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { FormAutocomplete } from '../../UI/Form/FormAutocomplete';
import { FormInputText } from '../../UI/Form/FormInputText';
import { FormButtonSubmit } from '../../UI/Form/FormButtonSubmit';
import { createField } from '../../../store/index.service';
import { Toastify } from "../../UI/Toastify/Toastify";

export const CreateField = ({ handleClosePopup, listGroup, setRefreshDataGroup, handleGetData }) => {
    const { control, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => {
        setLoading(true); 
        createField(data).then(res => {
            setLoading(false);
            handleClosePopup();
            Toastify("createField", "success");
            setRefreshDataGroup(true);
            handleGetData();
        });
    }

    return (
        <div className='popupCreateHorseQuick popup-container'>

            <h2>Ajouter une colonne</h2>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="form-group">
                    <FormInputText name="field" control={control} label="Champs" rules={{ required: false }} />
                </div>
                <div className="form-group">
                    <FormAutocomplete
                        name="type"
                        control={control}
                        label="Type de champs"
                        options={[
                            { id: 1, title: "Texte" },
                            { id: 2, title: "Chiffre" }
                        ]}
                        getOptionLabel={option => option.title}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                {option.title}
                            </li>
                        }
                    />
                </div>

                <div className="btn-container">
                    <Button variant="text" size="small" className="cancel" onClick={handleClosePopup}>Annuler</Button>
                    <FormButtonSubmit loading={loading} label="Créer" />
                </div>
            </form>


        </div>
    )
}