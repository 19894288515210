import api from '../api';

export const getListCampaign = async () => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace') + '/campaign' , {});
    return(result.data);
}

export const getOneCampaign = async (id) => {
    const result = await api.get('/api/workspace/' + localStorage.getItem('idWorkspace')  + '/campaign/' + id , {});
    return(result.data);
}

export const createCampaign = (data) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/campaign', data);

export const updateCampaign = (data, id) => api.put('/api/workspace/' + localStorage.getItem('idWorkspace') + '/campaign/' + id, data)

export const deleteCampaign = (id) => api.delete('/api/workspace/' + localStorage.getItem('idWorkspace') + '/campaign/' + id, {})

export const duplicateCampaign = (id) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/duplicate/campaign/' + id , {})