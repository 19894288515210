import { Link } from 'react-router-dom';

export const UpgradePlan = ({ handleClosePopup }) => {

    return (
        <div className='popupCreateHorseQuick upgradePlan'>
            <h3>Vous avez atteint la limite de votre consommation sur votre forfait actuel.</h3>
            <p>
                Pour continuer à profiter pleinement de Whakup et de toutes ses fonctionnalités, nous vous recommandons de passer à un forfait supérieur. <br />
                Cela vous permettra de débloquer des capacités supplémentaires et d'accéder à une utilisation illimitée de Whakup.
            </p>

            <Link to="/compte/abonnement" className="btn-add">Mettre à jour mon plan</Link>
        </div>
    )
}