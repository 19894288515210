import './privacyPolicy.scss';

export const PrivacyPolicy = () => {

    return (
        <div className="politique">

            <h1>Politique de Confidentialité de UXIA</h1>

            <h3>1. Introduction</h3>

            <p>UXIA, située au 49 RUE DE PONTHIEU 75008 PARIS, Siren 848276135, est déterminée à protéger et à respecter votre vie privée. Nous comprenons l'importance de la confidentialité des données et nous nous engageons à gérer vos données personnelles en toute sécurité et transparence.</p>

            <h3>2. Données collectées</h3>

            <p>
                Nous pouvons collecter, utiliser, stocker et transférer différents types de données personnelles vous concernant, qui ont été regroupées comme suit : <br />
                Identité et coordonnées : nom, adresse e-mail, numéro de téléphone, etc.<br />
                Données techniques : adresse IP, vos identifiants de connexion, type et version du navigateur, etc.<br />
                Données d'utilisation : informations sur la manière dont vous utilisez notre site web, produits et services.
            </p>

            <h3>3. Utilisation des données</h3>

            <p>
                Nous utiliserons vos données personnelles dans diverses situations, notamment : <br />
                Pour vous fournir nos produits et services. <br />
                Pour vous envoyer des communications marketing et promotionnelles. <br />
                Pour vous informer des modifications de nos services. <br />
                Pour améliorer notre site web et nos produits/services.
            </p>

            <h3>4. Partage des données</h3>

            <p>
                Nous pouvons partager vos données personnelles avec des tiers dans certaines circonstances, notamment avec :<br />
                Les prestataires de services qui fournissent des services de TI et de système.<br />
                Les autorités réglementaires ou autres autorités.
            </p>

            <h3>5. Sécurité des données</h3>

            <p>
                Nous avons mis en place des mesures de sécurité pour empêcher que vos données personnelles ne soient accidentellement perdues, utilisées ou accessibles de manière non autorisée, modifiées ou divulguées.
            </p>

            <h3>6. Vos droits</h3>
            <p>Vous avez des droits en ce qui concerne vos données personnelles, y compris le droit de demander l'accès, la correction, la suppression, la restriction, le transfert, de s'opposer au traitement, à la portabilité des données et (là où le fondement légitime du traitement est le consentement) de retirer le consentement.</p>
            <h3>7. Modifications de la politique de confidentialité</h3>
            <p>Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment, et nous vous fournirons une nouvelle politique de confidentialité lorsque nous apporterons des modifications substantielles.</p>
            <h3>8. Contact</h3>
            <p>Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à contact@uxia.com .</p>
        </div>
    )
}