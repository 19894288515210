import axios from 'axios';

const Axios = axios.create({
	baseURL: `https://api.whakup.com`,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	timeout: 30000,
});

export default Axios;