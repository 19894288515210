import { afterLoginMeta, generateFirstTokenMeta } from '../../store/index.service';

export const launchWhatsAppSignup = () => {
    if (!window.FB)
        return;

    window.FB.login(function (response) {
        if (response.status === 'connected') {
            generateFirstTokenMeta({
                code: response.authResponse.code,
            });
        }
    }, 
    {
        scope: "whatsapp_business_management, whatsapp_business_messaging",
        config_id: '927051512485898',
        response_type: 'code',
        override_default_response_type: true,
        extras: {
            sessionInfoVersion: 2
        }
    });

    const sessionInfoListener = (event) => {
        try {
            const data = JSON.parse(event.data);

            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                if (data.event === 'FINISH') {
                    afterLoginMeta({
                        wabaId: data.data.waba_id,
                        phoneId: data.data.phone_number_id,
                        type: 'whatsapp_signup_finished'
                    });
                } else {
                    afterLoginMeta({
                        event: data.event,
                        step: data.data.current_step
                    });
                }
            }
        } catch {
            return;
        }
    };
      
    window.addEventListener('message', sessionInfoListener);  
};