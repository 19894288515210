import ClipLoader from "react-spinners/ClipLoader";
import { Button } from "@mui/material";

export const FormButtonSubmit = (props) => {

    const { loading, label, onClick, className } = props;

    return (
        <Button 
            variant="contained"
            disableElevation
            fullWidth
            type="submit"
            onClick={onClick ? onClick : undefined}
            className={className ? className : ""}
        >
            {label}
            <ClipLoader
                color={"#fff"}
                loading={loading}
                size={20}
                cssOverride={{ marginLeft: 5 }}
            />
        </Button>
    );
};