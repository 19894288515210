import { useState, createContext } from 'react';

export const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
    const [idGroup, setIdGroup] = useState("");
    const [nameGroup, setNameGroup] = useState("");
    const [refreshDataGroup, setRefreshDataGroup] = useState("");
    const [listGroup, setListGroup] = useState({loading: true, data: []});

    return (
        <ContactContext.Provider value={{
            idGroup, setIdGroup,
            nameGroup, setNameGroup,
            refreshDataGroup, setRefreshDataGroup,
            listGroup, setListGroup
        }}>
            {children}
        </ContactContext.Provider>
    );
};
