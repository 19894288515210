import api from '../api';

export const getListFaq = async () => {
    const result = await api.get('/api/faq' , {});
    return(result.data);
}

export const getListFaqCategory = async () => {
    const result = await api.get('/api/faq-category' , {});
    return(result.data);
}

export const createFaqSearch = async (data) => {
    const result = await api.post('/api/faq-search' , data);
    return(result.data);
}