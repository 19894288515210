import { useState, createContext } from 'react';

export const MessagingContext = createContext();

export const MessagingProvider = ({ children }) => {
    const [idConversation, setIdConversation] = useState("");
    const [infoUser, setInfoUser] = useState("");
    const [phoneUser, setPhoneUser] = useState("");
    const [blacklistUser, setBlacklistUser] = useState("");
    const [idContact, setIdContact] = useState("");

    return (
        <MessagingContext.Provider value={{
            idConversation, setIdConversation,
            infoUser, setInfoUser,
            phoneUser, setPhoneUser,
            blacklistUser, setBlacklistUser,
            idContact, setIdContact
        }}>
            {children}
        </MessagingContext.Provider>
    );
};
