import './whatsAppExample.scss';
import defaultCaroussel from "../../assets/images/default-caroussel.png";
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const WhatsAppExampleCampaign  = ({template}) => {
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [footer, setFooter] = useState('');
    const [btnUrl, setBtnUrl] = useState([]);
    const [btnPhone, setBtnPhone] = useState([]);
    const [btnStopMarket, setBtnStopMarket] = useState([]);
    const [media, setMedia] = useState('');
    const [titleType, setTitleType] = useState('');
    const [carouselItems, setCarouselItems] = useState([]);

    useEffect(() => {
        setMessage('');
        setTitle('');
        setFooter('');
        setBtnUrl([]);
        setBtnPhone([]);
        setBtnStopMarket([]);
        setMedia('');
        setTitleType('');
        setCarouselItems([]);

        if (template && template.dataTemplate) {
            template.dataTemplate.components.forEach((components) => {

                if (components.type === 'HEADER') {
                    if (components.format === 'IMAGE') {
                        setMedia(template.media.mediaPath);
                        setTitleType('image');
                    } else if (components.format === 'VIDEO') {
                        setMedia(template.media.mediaPath);
                        setTitleType('video');
                    } else {
                        setTitle(components.text);
                        setTitleType('text');
                    }
                }

                if (components.type === 'BODY') {
                    const formattedContent = template.content;
                    setMessage(formattedContent);
                }

                if (components.type === 'FOOTER') {
                    setFooter(components.text);
                }

                if (components.type === 'BUTTONS') {
                    const urlBtn = template.dataTemplate.components
                        .filter(component => component.type === 'BUTTONS')
                        .flatMap(buttonComponent => buttonComponent.buttons.filter(button => button.type === 'URL'));
        
                    setBtnUrl(urlBtn);

                    const phoneBtn = template.dataTemplate.components
                        .filter(component => component.type === 'BUTTONS')
                        .flatMap(buttonComponent => buttonComponent.buttons.filter(button => button.type === 'PHONE_NUMBER'));

                    setBtnPhone(phoneBtn);

                    const stopMarketBtn = template.dataTemplate.components
                        .filter(component => component.type === 'BUTTONS')
                        .flatMap(buttonComponent => buttonComponent.buttons.filter(button => button.type === 'QUICK_REPLY'));
    
                    setBtnStopMarket(stopMarketBtn);
                }

                if (components.type === 'CAROUSEL') {
                    const carouselItems = components.cards || [];
                    setCarouselItems(carouselItems);
                }

            });

        }
    }, [template]);

	return (
		<div className="whatsapp-chat">
			<div className="chat-header">
				<p></p>
			</div>
			<div className="chat-messages">

				<div className="contentMessage">

					<div className="title">
                        {titleType === 'text' && (
                            title
                        )}
					</div>

					<div className="image">
                        {titleType === 'image' && (
							<div className="upload-img">
                                <img
                                    src={media}
                                    alt="image"
                                />
                            </div>
                        )}
					</div>

                    <div className="video">
                        {titleType === 'video' && (
                            <div className="video-container">
                                <video controls className="styled-video">
                                    <source
                                        src={media}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}
                    </div>

					<div className="message" dangerouslySetInnerHTML={{ __html: message }}></div>

					<div className="footer">
						{footer}
					</div>
                    
				</div>

                <div className="carrousel">
					<Swiper spaceBetween={5} slidesPerView={1.25}>
						{carouselItems?.map((cards, index) => (
							<SwiperSlide>
								<div className="carrousel-item">
                                    {cards.components.map((compo, index) => {
                                        if (compo.type === 'HEADER') {
                                            return (
                                                <img key={`header-${index}`} src={defaultCaroussel} alt="placeholder" />
                                            );
                                        } else if (compo.type === 'BODY') {
                                            return (
                                                <div key={`body-${index}`} className="carrousel-content" dangerouslySetInnerHTML={{ __html: compo?.text }}></div>
                                            );
                                        } else if (compo.type === 'BUTTONS') {
                                            return (
                                                <div key={`buttons-${index}`} className="actions">
                                                    {compo.buttons.map((button, buttonIndex) => (
                                                        <div className="button" key={`button-${buttonIndex}`}>
                                                            {button.type === 'URL' ? (
                                                                <a href={button.url} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                                                    {button.text}
                                                                </a>
                                                            ) : button.type === 'PHONE_NUMBER' ? (
                                                                <a href={`tel:${button.phoneNumber}`} target="_blank" rel="noreferrer">
                                                                    <FontAwesomeIcon icon={faPhone} />
                                                                    {button.text}
                                                                </a>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				<div className="button-list">
                    {btnUrl.map((button, index) => (
                        <div className="button" key={index}>
                            {button.url ? (
                                <a href={button.url} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    {button.text}
                                </a>
                            ) : (
                                button.text
                            )}
                        </div>
                    ))}

                    {btnPhone.map((buttonPhone, index) => (
                        <div className="button" key={index}>
                            {buttonPhone.phone_number ? (
                                <a href={`tel:${buttonPhone.phone_number}`} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faPhone} />
                                    {buttonPhone.text}
                                </a>
                            ) : (
                                buttonPhone.text
                            )}
                        </div>
                    ))}

                    {btnStopMarket.map((buttonStopMarket, index) => (
                        <div className="button" key={index}>
                            {buttonStopMarket.text ? (
                                buttonStopMarket.text
                            ) : (
                                buttonStopMarket.text
                            )}
                        </div>
                    ))}
                    
				</div>
				
			</div>
		</div>
	);
};
