import { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { isMobile } from 'react-device-detect';
import { StoreContext } from "./store/store";
import { useTranslation } from 'react-i18next';
import { getUserConnected, getCountMessageNotRead } from "./store/index.service";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header } from './components/Header/Header';
import { Navigation } from './components/Navigation/Navigation';
import { TrialOffer } from './components/TrialOffer/TrialOffer';
import { ScrollToTop } from "./components/UI/ScrollToTop/ScrollToTop";
import { Crisp } from './components/UI/Crisp/Crisp';
import { ReportBug } from './components/UI/ReportBug/ReportBug';

import { Login } from './pages/Auth/Login/Login';
import { Register } from './pages/Auth/Register/Register';
import { RequestPassword } from './pages/Auth/RequestPassword/RequestPassword';
import { ResetPassword } from './pages/Auth/ResetPassword/ResetPassword';

import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { LegalMention } from './pages/PrivacyPolicy/LegalMention';
import { Faq } from './pages/Faq/Faq';

import { Dashboard } from './pages/Dashboard/Dashboard';
import { Contacts } from './pages/Contacts/Contacts';
import { Messaging } from './pages/Messaging/Messaging';
import { Campaign } from './pages/Campaign/Campaign';
import { CreateCampaign } from './pages/Campaign/CreateCampaign';
import { UpdateCampaign } from './pages/Campaign/UpdateCampaign';
import { Template } from './pages/Template/Template';
import { CreateTemplate } from './pages/Template/CreateTemplate';
import { PersonalInfo } from './pages/Account/PersonalInfo/PersonalInfo';
import { Workspace } from './pages/Account/Workspace/Workspace';
import { Invoice } from './pages/Account/Invoice/Invoice';
import { Subscription } from './pages/Account/Subscription/Subscription';
import { Integration } from './pages/Account/Integration/Integration';

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_MUI);

const RedirectToDashboard = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/dashboard');
    }, [navigate]);
    return null;
};

export const App = () => {
	const { loggedIn, setUser, setWorkspace, lang, setNbMessageNotRead, setListWorkspace, isTrialOffer } = useContext(StoreContext);
	const { i18n } = useTranslation(); 

    useEffect(() => {
        if (loggedIn) {
            getUserConnected().then(res => {
                setUser(res.data.user);
				setListWorkspace(res.data.workspace);
				if (localStorage.getItem('idWorkspace') === null && res.data.workspace.length > 0) {
					setWorkspace(res.data.workspace[0]);
					localStorage.setItem('idWorkspace', res.data.workspace[0].workspaceId);
				} else {
					const workspace = res.data.workspace.find(w => String(w.workspaceId) === String(localStorage.getItem('idWorkspace')));
					setWorkspace(workspace);
				}
            });

			getCountMessageNotRead().then(res => {
				setNbMessageNotRead(res);
			});
        } else {
			localStorage.removeItem('token');
			localStorage.removeItem('idWorkspace');
			setUser({});
		}
    }, [loggedIn, setUser, setWorkspace, setNbMessageNotRead]);

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	return (
		<BrowserRouter>
			<div className="whakup">
				<ScrollToTop />
				{!loggedIn ?
					<>
						<Routes>
							<Route path="/" element={<Login />} />
							<Route path="/mot-de-passe-oublier" element={<RequestPassword />} />
							<Route path="/reset-password/:token" element={<ResetPassword />} />
							<Route path="/inscription" element={<Register />} />
							<Route path="/inscription/:token" element={<Register />} />
							<Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
							<Route path="/mentions-legales" element={<LegalMention />} />
							<Route path="*" element={<Navigate replace to="/" />} />
						</Routes>
					</>
				: 
					<>
						<div className="whakup-container">
							{!isMobile && <>
							
								<TrialOffer />
								<Navigation />
								<div className="whakup-content">
								<Header />
									<div className="whakup-content-container">
										<div className={isTrialOffer ? 'isTrial' : ''}>
											<Routes>
												<Route path="/dashboard" element={<Dashboard />} />
												<Route path="/dashboard/invit/:token" element={<Dashboard />} />
												<Route path="/contact" element={<Contacts />} />
												<Route path="/messagerie" element={<Messaging />} />

												<Route path="/campagnes" element={<Campaign />} />
												<Route path="/campagnes/create" element={<CreateCampaign />} />
												<Route path="/campagnes/update/:id" element={<UpdateCampaign />} />

												<Route path="/template" element={<Template />} />
												<Route path="/template/create" element={<CreateTemplate />} />
												
												<Route path="/compte/profile" element={<PersonalInfo />} />
												<Route path="/compte/espace-de-travail" element={<Workspace />} />
												<Route path="/compte/facture" element={<Invoice />} />
												<Route path="/compte/abonnement" element={<Subscription />} />
												<Route path="/compte/meta" element={<Integration />} />
												
												<Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
												<Route path="/mentions-legales" element={<LegalMention />} />
												<Route path="/faq" element={<Faq />} />

												{/* Rediriger vers le tableau de bord si connecté et essayant d'accéder à "/" */}
												<Route path="/" element={<RedirectToDashboard />} />
												{/* Rediriger vers le tableau de bord si une route non définie est demandée */}
												<Route path="*" element={<Navigate replace to="/dashboard" />} />
											</Routes>
										</div>
									</div>
								
									<ReportBug />
									<Crisp />
									<ToastContainer 
										position="top-right"
										autoClose={5000}
										hideProgressBar
										newestOnTop={false}
										closeOnClick={false}
										rtl={false}
										pauseOnFocusLoss={false}
										draggable={false}
										pauseOnHover={false}
										theme="colored"
									/>
								</div>
								</>
							}

							{isMobile && <>
								<div className="mobile-container">
									<h1>Affichage sur ordinateur uniquement</h1>
									<p>L'application est optimisée pour un usage sur navigateur d'ordinateur. Merci de vous reconnecter depuis un ordinateur.</p>
								</div>
							</>}
						</div>
					</>
				}
			</div>
		</BrowserRouter>
	);
}