import './login.scss';
import media from '../../../assets/images/WhatsApp-marketing-campaign-1.jpeg';
import logoGreen from '../../../assets/images/logo-green.svg';

import { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Cookies from 'js-cookie';
import ClipLoader from "react-spinners/ClipLoader";
import { StoreContext } from '../../../store/store';
import { signIn, loginUser } from "../../../store/index.service";
import { FormInputText } from "../../../components/UI/Form/FormInputText";

export const Login = () => {
    const { handleSubmit, control } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const { setLoggedIn } = useContext(StoreContext);

    const onSubmit = async (data) => {
        setLoading(true);
        
        signIn(data)
            .then(res => {
                Cookies.set('loggedIn', true, { expires: 1 });
                localStorage.setItem('token', res.data.token);
                setLoggedIn(true);
                loginUser();
            })
            .catch(err => {
                setError(
                    err.response.status === 401 ? err.response.status : "default"
                );
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className="login">

            <div className="login-media">
                <img src={media} alt="media" />
            </div>

            <div className="login-form">
                <div className="logo-whakup">
                    <img src={logoGreen} alt="logo" />
                </div>

                <div className="title">
                    <h1>Bienvenue sur Whakup</h1>
                </div>

                <div className="form">
                    <form name="login" className="register-form" onSubmit={handleSubmit(onSubmit)}>

                        { error &&
                            <div className='error-msg'>
                                <span>Email ou mot de passe incorrect</span>
                            </div>
                        }

                        <div className="form-group">
                            <FormInputText name="username" control={control} rules={{ required: true }} label="Email" type="email" />
                        </div>
                        <div className="form-group">
                            <FormInputText name="password" control={control} rules={{ required: true }} label="Mot de passe" type="password" />
                        </div>

                        <div className="form-group">
                            <div className="form-check">
                                <input type="checkbox" id="rememberMe" name="rememberMe" />
                                <label htmlFor="rememberMe">Se souvenir de moi</label>
                            </div>
                            <Link to="/mot-de-passe-oublier">Mot de passe oublier ?</Link>
                        </div>


                        <Button className="btn-submit" variant="contained" disableElevation type="submit" size='large'>
                            <ClipLoader color={"#fff"} loading={loading} size={20} cssOverride={{ marginRight: 5 }} />
                            Se connecter
                        </Button>

                        <div className="form-group">
                            <p className="no-account">Vous n'avez pas de compte ? <Link to="/inscription">Inscrivez-vous</Link></p>
                        </div>
                    </form>
                </div>

            </div>
        </div>
  	);
} 