import './kpi.scss';
import { KpiWorkspace } from './Data/KpiWorkspace';

export const Kpi = () => {
    return (

        <div className="kpi">
            <h2>Mes statistiques</h2>

            <KpiWorkspace />
        </div>
  	);
} 