import api from '../api';


export const getListMemberInWorkspace = async () => {
    const result = await api.get('/api/workspace-user/' + localStorage.getItem('idWorkspace'), {});
    return(result.data);
}

export const updateRoleMemberInWorkspace = (data, id) => api.put('/api/workspace/' + localStorage.getItem('idWorkspace') + '/workspace-user/' + id + '/roles', data)

export const invitMemberInWorkspace = (data) => api.post('/api/workspace/' + localStorage.getItem('idWorkspace') + '/invited/workspace-user', data)

export const joinWorkspace = (token) => api.post('/api/invited/workspace-user/' + token, {})

export const deleteMemberInWorkspace= (id) => api.delete('/api/workspace/' + localStorage.getItem('idWorkspace') + '/workspace-user/' + id, {})


export const getUserInvited = async (token) => {
    const result = await api.get('/api/register/invited/' + token, {});
    return(result.data);
}